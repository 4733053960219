import { HttpClient, HttpParams } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { AutenticacaoService } from './autenticacao.service'

@Injectable({
  providedIn: 'root'
})
export class TermoService {
  baseUrl = environment.controleUrl
  token: string

  constructor(private http: HttpClient, private autenticacaoService: AutenticacaoService) {
    this.token = autenticacaoService.token().split('.').join('[token]')
  }

  pegarTermo(): Observable<any> {
    return this.http.get<any>(this.baseUrl + 'Termo/Pegar?token=' + this.token)
  }

  aceitarTermo() {
    return this.http.post(this.baseUrl + 'Termo/Aceitar?token=' + this.token, null, { responseType: 'text' })
  }
}
