import { Component, OnInit } from '@angular/core'
import { iconesMenu, rotas } from 'src/app/constantes'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'
import { MenuAsideService, MenuConfigService } from 'src/app/core/_base/layout'
import { Sistema } from 'src/app/core/_base/layout/models/sistema.enum'
import { ModulosService } from 'src/app/core/shared/services/modulos.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-sistemas',
  templateUrl: './sistemas.component.html',
  styleUrls: ['./sistemas.component.scss']
})
export class SistemasComponent implements OnInit {
  sistemaAtual: Sistema
  botoesSistema: any[] = []

  constructor(
    private menuConfigService: MenuConfigService,
    private menuAsideService: MenuAsideService,
    private authService: AutenticacaoService,
    private modulosService: ModulosService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.sistemaAtual = this.menuConfigService.pegarSistemaAberto()
    this.botoesSistema = [
      { icone: iconesMenu.contabil, sistema: Sistema.Contabil, tooltip: 'Contabil' },
      { icone: iconesMenu.folha, sistema: Sistema.Folha, tooltip: 'Folha' },
      { icone: iconesMenu.fiscal, sistema: Sistema.Fiscal, tooltip: 'Fiscal' },
      { icone: iconesMenu.gerenciador, sistema: Sistema.Gerenciador, tooltip: 'Gerenciador' }
    ]
  }

  mudarMenu(sistema: Sistema) {
    this.sistemaAtual = this.menuConfigService.pegarSistemaAberto()
    if (this.sistemaAtual === sistema) {
      return;
    }

    const sistemaString = this.modulosService.converterSistemaParaString(sistema)
    const temPermissao = this.authService.pegarUsuario().permissoes.includes(sistemaString);

    if (!temPermissao) {
      // Redirecionar para a página de acesso negado ou outra página relevante
      this.router.navigate(['/' + rotas.acessoNegado + '/' + sistemaString]);
      return;
    }

    this.sistemaAtual = sistema
    this.menuConfigService.salvarSistemaAberto(sistema)
    this.menuConfigService.loadConfigs()
    this.menuAsideService.loadMenu()
  }

}
