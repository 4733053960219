import { Injectable } from '@angular/core'
//import toastr from 'toastr'
import { UntypedFormGroup, UntypedFormArray, AbstractControl } from '@angular/forms'
import { AutenticacaoService } from './autenticacao.service'
import { ToastrService } from 'ngx-toastr'
import { HttpErrorResponse } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class MensagemService {
  private tituloSucesso = 'Sucesso'
  private tituloInformacao = 'Informação'
  private tituloAviso = 'Aviso'
  private tituloErro = 'Erro'
  private mensagemErro = 'Aconteceu um erro inesperado. Contate o suporte ou tente novamente mais tarde!'
  private mensagemErroFormGroup = 'Favor preencher os campos corretamente!'
  private mensagemErroFormGroupValidacao = 'Favor preencher os seguintes campos corretamente: '


  constructor(private autenticacaoService: AutenticacaoService, private toastr: ToastrService) { }

  public showErroApi(httpErrorResponse: HttpErrorResponse, form: UntypedFormGroup = null) {
    try {
      // 14/11/2023 - Comentado porque estava dando erro em const error = JSON.parse(httpErrorResponse.error)
      //const error = JSON.parse(httpErrorResponse.error)
      //   Sendo httpErrorResponse.error = {
      //     "retorno": null,
      //     "valido": false,
      //     "invalido": true,
      //     "notifications": [
      //         {
      //             "key": "TurnoId",
      //             "message": "Turno não encontrado"
      //         }
      //     ],
      //     "isValid": false
      // } porque poperia estar dando erro em const error = JSON.parse(httpErrorResponse.error)
      //Se você está recebendo esse tipo de resposta de erro, a função JSON.parse deve estar gerando um erro porque
      // httpErrorResponse.error já é um objeto JavaScript e não precisa ser convertido de uma string JSON.
      // A função JSON.parse é usada para analisar uma string JSON e convertê-la em um objeto JavaScript.
      // Se você já tem um objeto JavaScript, não é necessário usar JSON.parse.

      const error = httpErrorResponse.error
      if (httpErrorResponse.status === 401)
        this.autenticacaoService.deslogar()
      else {
        if (error.invalido === true) {
          if (error.notifications.length > 0) {
            const mensagensSemControl: string[] = []
            if (form !== null && form !== undefined) {
              let control: AbstractControl
              error.notifications.forEach(notificacao => {
                control = form.get(notificacao.key.replace(/(?:^|[.])\S/g, function(a) { return a.toLowerCase() }))
                if (control !== undefined && control !== null)
                  control.setErrors({errorMessage: notificacao.message})
                else
                  mensagensSemControl.push(notificacao.message)
              })
              this.percorrerFormGroup(form)
            } else
              mensagensSemControl.push(...error.notifications.map(n => n.message))

            if (mensagensSemControl.length > 0)
              this.showAviso(mensagensSemControl.join('\n'))
          } else
            this.showAviso(this.mensagemErroFormGroup)
        } else if (error.result)
          this.showErro(error.result)
        else if (error.errors)
          this.showErro(this.mensagemErro)
        else if (error)
          this.showErro(error)
        else
          this.showErro(this.mensagemErro)
      }
    } catch { this.showErro(this.mensagemErro) }
  }

  public showSucesso(mensagem: string) {
    this.toastr.success(mensagem, this.tituloSucesso, {progressBar: true})
  }

  public showErro(mensagem: string) {
    this.toastr.error(mensagem, this.tituloErro, {progressBar: true})
  }

  public showAviso(mensagem: string) {
    this.toastr.warning(mensagem, this.tituloAviso, {progressBar: true})
  }

  public showInformacao(mensagem: string) {
    this.toastr.info(mensagem, this.tituloInformacao, {progressBar: true})
  }

  public verificaFormGroup(form: UntypedFormGroup): boolean {
    if (form.invalid) {
      const camposInvalidos = this.obterCamposInvalidos(form);
      this.percorrerFormGroup(form)
      //this.showErro(this.mensagemErroFormGroup)
      this.showErro(this.mensagemErroFormGroupValidacao + camposInvalidos.join(', '));
      return false
    } else
      return true
  }

  private percorrerFormGroup(form: UntypedFormGroup|UntypedFormArray) {
    for (const field in form.controls) {
      if (field !== null) {
        form.controls[field].markAsTouched()
        this.percorrerFormGroup(form.controls[field] as UntypedFormGroup|UntypedFormArray)
      }
    }
  }

  private obterCamposInvalidos(form: UntypedFormGroup): string[] {
    const camposInvalidos: string[] = [];

    Object.keys(form.controls).forEach(key => {
      const control = form.get(key) as AbstractControl;

      if (control.invalid) {
        camposInvalidos.push(key);
      }
    });

    return camposInvalidos;
  }

}
