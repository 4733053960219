import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { rotas } from 'src/app/constantes'
import { Usuario } from 'src/app/core/models/autenticacao/Usuario'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'
import { MensagemService } from 'src/app/core/services/mensagem.service'

@Injectable({
  providedIn: 'root'
})
export class GerenciadorGuard implements CanActivate {
  modalRef: BsModalRef
  modulo = 'Gerenciador'

  constructor(private authService: AutenticacaoService, private router: Router,
              private modalService: BsModalService, private mensagemService: MensagemService) {}

  canActivate(): boolean {
      const usuario: Usuario = this.authService.pegarUsuario()
      if (usuario.permissoes.some(p => p === this.modulo)) {
        return true
      }
      this.router.navigate(['/' + rotas.acessoNegado + '/' + this.modulo])
      return false
  }

}
