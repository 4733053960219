// Angular
import { AfterViewInit, Component, OnInit } from '@angular/core'
// Layout
import { LayoutConfigService, ToggleOptions } from 'src/app/core/_base/layout'
import { HtmlClassService } from 'src/app/views/theme/html-class.service'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'

@Component({
  selector: 'app-brand',
  templateUrl: './brand.component.html',
})
export class BrandComponent implements OnInit, AfterViewInit {
  // Public properties
  headerLogo = ''
  brandClasses = ''
  asideSelfMinimizeToggle = true

  toggleOptions: ToggleOptions = {
    target: 'kt_body',
    targetState: 'aside-minimize',
    toggleState: 'active'
  }

  /**
   * Component constructor
   *
   * @param layoutConfigService: LayoutConfigService
   * @param htmlClassService: HtmlClassService
   */
  constructor(private layoutConfigService: LayoutConfigService,
              public htmlClassService: HtmlClassService,
              private autenticacaoService: AutenticacaoService) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.headerLogo = this.getAsideLogo()
    this.brandClasses = this.htmlClassService.getClasses('brand', true).toString()
    this.asideSelfMinimizeToggle = this.layoutConfigService.getConfig('aside.self.minimize.toggle')
  }

  /**
   * On after view init
   */
  ngAfterViewInit(): void {
  }

  getAsideLogo() {
    const usuario = this.autenticacaoService.pegarUsuario()
    return usuario.logo && usuario.logo !== '' ? usuario.logo : 'https://digibyteblob.blob.core.windows.net/digibyteblob/Digibyte.png'
  }

  toggleAsideClick() {
    document.body.classList.toggle('aside-minimize')
  }
}
