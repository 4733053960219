import { Component, OnInit } from '@angular/core'
import { BsLocaleService } from 'ngx-bootstrap/datepicker'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { MensagemService } from 'src/app/core/services/mensagem.service'

@Component({
  selector: 'app-escolha-data-movimento',
  templateUrl: './escolha-data-movimento.component.html'
})
export class EscolhaDataMovimentoComponent implements OnInit {
  dataMovimento: Date
  novaData: boolean = false
  public onClose: Subject<Date> = new Subject<Date>()

  constructor(
    private mensagemService: MensagemService,
    public bsModalRef: BsModalRef,
    private localeService: BsLocaleService) { }

  ngOnInit() {
    this.localeService.use('pt-BR')
  }

  fecharModal() {
    if (this.dataMovimento === null || this.dataMovimento === undefined || this.dataMovimento.toString() === 'Invalid Date') {
      this.mensagemService.showAviso('Por favor, escolha uma data.')
    } else {
      this.onClose.next(this.dataMovimento)
      this.bsModalRef.hide()
    }
  }
}
