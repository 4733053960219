<div class="modal-header header-grid">
  <h4 class="modal-title">Movimento</h4>
</div>
<div class="modal-body">
  <div class="col-12 form-group">
    <label>Informe Movimento:</label>
    <input
      [(ngModel)]="dataMovimento"
      (ngModelChange)="novaData = true"
      class="form-control form-control-sm"
      placeholder="Data movimento"
      type="text"
      bsDatepicker
      [bsConfig]="{
        isAnimated: true,
        adaptivePosition: true,
        minMode: 'month',
        dateInputFormat: 'MMMM/YYYY' }"
    />
  </div>
</div>
<div class="modal-footer footer-grid">
  <button type="button"
    (click)="fecharModal()"
    class="btn btn-light btn-sm float-right"
  >{{ novaData ? 'Salvar' : 'Fechar' }}</button>
</div>
