export enum EsocialEventos {
  S1000 = "Empregador/Contribuinte/Órgão Público",
  S1005 = "Estabelecimentos, Obras ou Unidades de Órgãos Públicos",
  S1010 = "Tabela de Rúbricas",
  S1020 = "Tabela de Lotações Tributárias",
  S1070 = "Tabela de Processos Administrativos/Judiciais",
  S1200 = "Remun. de Trabalhador Regime Geral de Previd. Social",
  S1202 = "Remun. de Servidor Regime Próprio de Previd. Social",
  S1207 = "Benefícios - Entes Públicos",
  S1210 = "Pag. de Rendimentos do Trabalho",
  S1260 = "Comercialização da Produção Rural Pessoa Física",
  S1270 = "Contratação de Trabalhadores Avulsos Não Portuários",
  S1280 = "Inf. Complementares aos Eventos Periódicos",
  S1298 = "Reabertura dos Eventos Periódicos",
  S1299 = "Fechamento dos Eventos Periódicos",
  S2190 = "Registro Preliminar de Trabalhador",
  S2200 = "Cadastramento Inicial e Admissão/Ingresso de Trabalhador",
  S2205 = "Alteração de Dados Cadastrais do Trabalhador",
  S2206 = "Alteração de Contrato de Trabalho/Relação Estatutária",
  S2210 = "Comunicação de Acidente de Trabalho",
  S2220 = "Monitoramento da Saúde do Trabalhador",
  S2230 = "Afastamento Temporário",
  S2231 = "Cessão/Exercício em Outro Órgão",
  S2240 = "Condições Ambientais do Trabalho - Agentes Nocivos",
  S2298 = "Reintegração/Outros Provimentos",
  S2299 = "Desligamento",
  S2300 = "Trab. Sem Vínculo/Estatutário - Início",
  S2306 = "Trab. Sem Vínculo/Estatutário - Alteração Contratual",
  S2399 = "Trab. Sem Vínculo/Estatutário - Término",
  S2400 = "Cadastro de Beneficiário - Entes Públicos - Início",
  S2405 = "Cadastro de Beneficiário - Entes Públicos - Alteração",
  S2410 = "Cadastro de Benefício - Entes Públicos - Início",
  S2416 = "Cadastro de Benefício - Entes Públicos - Alteração",
  S2418 = "Reativação de Benefício - Entes Públicos",
  S2420 = "Cadastro de Benefício - Entes Públicos - Término",
  S2500 = "Processo Trabalhista",
  S2501 = "Inf. de Tributos Decorrentes de Processo Trabalhista",
  S3000 = "Exclusão de Eventos",
  S3500 = "Exclusão de Eventos - Processo Trabalhista",
  S5001 = "Inf. das Contribuições Sociais por Trabalhador",
  S5002 = "Imposto de Renda Retido na Fonte por Trabalhador",
  S5003 = "Inf. do FGTS por Trabalhador",
  S5011 = "Inf. das Contribuições Sociais Consolidadas por Contribuinte",
  S5012 = "Imposto de Renda Retido na Fonte Consolidado por Contribuinte",
  S5013 = "Inf. do FGTS Consolidadas por Contribuinte",
  S5501 = "Inf. Consolidadas de Tributos Decorrentes de Proc. Traba.",
  S5503 = "Inf. do FGTS por Trabalhador em Proc. Traba.",
  S8200 = "Anotação Judicial do Vínculo",
  S8299 = "Baixa Judicial do Vínculo"
}
