import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { rotas } from 'src/app/constantes'
import { Usuario } from 'src/app/core/models/autenticacao/Usuario'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'

@Injectable({
  providedIn: 'root'
})
export class CompartilhadaGuard implements CanActivate {
  modalRef: BsModalRef
  modulo: string[] = ['Contabil', 'Folha', 'Fiscal','Gerenciador']
  modulosEncontrados: string[] =[]
  valores: boolean = false

  constructor(private authService: AutenticacaoService, private router: Router) {}

  canActivate(): boolean {
    const usuario: Usuario = this.authService.pegarUsuario()
    for (let i = 0; i < this.modulo.length; i++) {
      if (usuario.permissoes.some(p => p === this.modulo[i])) {
        this.modulosEncontrados.push(this.modulo[i])
        this.valores = true
      }
    }

    if(!this.valores){
      this.router.navigate(['/' + rotas.acessoNegado + '/' + this.modulo])
    }

    return this.valores;
  }
}
