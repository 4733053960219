// Angular
import { AfterViewInit, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit  {
  @Input() icon = 'flaticon2-shopping-cart-1'
  @Input() iconType: '' | 'brand'
  @Input() useSVG: boolean
  @Input() bgImage: string
  @Input() linkSuporte: string
  @Input() modalRef: BsModalRef
  @ViewChild('modalSuporte') modalSuporte: TemplateRef<any>
  sistema:any;
  constructor(private modalService: BsModalService) {}

  ngOnInit(): void {
    this.sistema =  { tooltip: 'Suporte' }
  }


  abrirOpcoesSuporte() {
    this.modalRef = this.modalService.show(this.modalSuporte)
  }

  abrirChat() {
    if (this.linkSuporte && this.linkSuporte !== '') {
      window.open(this.linkSuporte, '_blank')
    }
    this.modalRef.hide()
  }

  baixarChat() {
    if (this.linkSuporte && this.linkSuporte !== '') {
      window.open('https://www.digibyte.com.br/downloads/utilitarios/setup-suporte-remoto.exe', '_blank')
    }
    this.modalRef.hide()
  }
}
