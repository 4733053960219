import { LotacaoTributariaService } from './../../lotacao-tributaria/shared/lotacao-tributaria.service';
import { TurnosService } from 'src/app/views/pages/folha/turnos/shared/turnos.service';
import { Observable, catchError, map, throwError } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { formatDate } from '@angular/common'
import { Retorno } from 'src/app/core/models/retorno'
import { Preliminar } from 'src/app/core/models/funcionarios/funcionarios-preliminares'
import { Ferias } from 'src/app/core/models/funcionarios/ferias'
import { Intermitente } from './intermitente'
import { Dependente } from 'src/app/views/pages/folha/funcionarios/shared/dependente.model'
import { BaseService } from 'src/app/core/shared/services/base.service'
import { environment } from 'src/environments/environment'
import { CriptografiaService } from 'src/app/core/shared/services/criptografia.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { ColaboradorComVinculo } from './colaboradorComVinculo'
import { ColaboradorSemVinculo } from './colaboradorSemVinculo'
import { Departamento } from '../../departamento/shared/departamento.model'
import { MensagemService } from 'src/app/core/services/mensagem.service'
import { DepartamentosService } from '../../departamento/shared/departamento.service'
import { CargosService } from '../../cargo/shared/cargos.service'
import { Cargo } from '../../cargo/shared/cargo.model'
import { Turno } from '../../turnos/shared/turnos.model'
import { LotacaoTributaria } from '../../lotacao-tributaria/shared/lotacao-tributaria.model'

@Injectable({
  providedIn: 'root'
})
export class ColaboradoresService extends BaseService {
  baseUrl = environment.apiUrl

  private STR_PRELIMINAR = 'PRELIMINAR'
  router: any

//  mensagemService: any

  constructor(
    protected http: HttpClient,
    protected criptografiaService: CriptografiaService,
    protected variaveisContextoService: VariaveisContextoService,
    private mensagemService: MensagemService,
    private departamentoService: DepartamentosService,
    private cargosService: CargosService,
    private turnosService: TurnosService,
    private lotacaoTributariaService: LotacaoTributariaService,
  ) {
    super(variaveisContextoService)
  }

  //#region gets
  pegarColaboradoresPorEmpresa(): Observable<Retorno<ColaboradorComVinculo[]>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<ColaboradorComVinculo[]>>(
      this.baseUrl + 'v1/empresas/' + this.empresaId + '/funcionarios'
    )
  }

  pegarPreliminarPorId(id: number): Observable<Retorno<Preliminar>> {
    return this.http.get<Retorno<Preliminar>>(
      this.baseUrl + 'v1/funcionarios/preliminares/' + id
    )
  }

  pegarColaboradoresEPreliminares(): Observable<Retorno<any[]>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<any[]>>(
      this.baseUrl + 'v1/empresas/' + this.empresaId + '/funcionarios-e-preliminares'
    )
  }

  pegarColaboradoresComFerias(): Observable<Retorno<any[]>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<any[]>>(
      this.baseUrl + 'v1/empresas/' + this.empresaId + '/funcionariosComFerias'
    )
  }

  pegarColaboradorPorId(id: number): Observable<Retorno<ColaboradorComVinculo>> {
    return this.http.get<Retorno<ColaboradorComVinculo>>(
      this.baseUrl + 'v1/funcionarios/' + id
    )
  }

  pegarDadosNgSelectContrato(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/funcionarios/listas-de-preenchimento'
    )
  }

  pegarFeriasPorData(id: number, dataMovimento: Date): Observable<Retorno<Ferias>> {
    return this.http.get<Retorno<Ferias>>(
      this.baseUrl + 'v1/funcionarios/' + id + '/ferias/' + formatDate(
        dataMovimento, 'MM-dd-yyyy', 'pt-BR'
      )
    )
  }

  pegarFeriasPorFuncionario(id: number, dataMovimento: Date): Observable<Retorno<Ferias[]>> {
    return this.http.get<Retorno<Ferias[]>>(
      this.baseUrl + 'v1/funcionarios/' + id + '/ferias/'  + formatDate(
        dataMovimento, 'MM-dd-yyyy', 'pt-BR'
      )
    )
  }

  pegarIntermitente(funcionarioId: number): Observable<Retorno<Intermitente>> {
    return this.http.get<Retorno<Intermitente>>(
      this.baseUrl + 'v1/funcionarios/' + funcionarioId + '/intermitente'
    )
  }

  pegarIntermitentePorFuncionario(funcionarioId: number, intermitenteId: number): Observable<Retorno<Intermitente>> {
    return this.http.get<Retorno<Intermitente>>(
      this.baseUrl + 'v1/funcionarios/' + funcionarioId + '/intermitente/' + intermitenteId
    )
  }

  pegarDependentesDoColaborador(id: number): Observable<Retorno<Dependente[]>> {
    return this.http.get<Retorno<Dependente[]>>(
      this.baseUrl + 'v1/funcionarios/' + id + '/dependentes'
    )
  }

  pegarDependenteDoColaborador(id: number, dependenteId: number): Observable<Retorno<Dependente>> {
    return this.http.get<Retorno<Dependente>>(
      this.baseUrl + 'v1/funcionarios/' + id + '/dependentes/' + dependenteId
    )
  }

  pegarColaboradoresComLancamentoVt(dataMovimento): Observable<Retorno<any>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/empresas/' + this.empresaId + '/funcionarios/LancamentoVT/' +
      formatDate(dataMovimento, 'MM-dd-yyyy', 'pt-BR')
    )
  }
  //#endregion

  //#region posts
  salvarColaboradorComVinculo(colaborador: ColaboradorComVinculo) {
    return this.http.post<Retorno<ColaboradorComVinculo>>(
      this.baseUrl + 'v1/funcionariosComVinculo',
      colaborador
    )
  }

  salvarColaboradorSemVinculo(colaborador: ColaboradorSemVinculo) {
    return this.http.post<Retorno<ColaboradorSemVinculo>>(
      this.baseUrl + 'v1/funcionariosSemVinculo',
      colaborador
    )
  }

  salvarPreliminar(preliminar: Preliminar) {
    return this.http.post<Retorno<Preliminar>>(
      this.baseUrl + 'v1/funcionarios/preliminares',
      preliminar
    )
  }

  salvarFerias(ferias: Ferias) {
    return this.http.post<Retorno<Ferias>>(
      this.baseUrl + 'v1/funcionarios/feriasPeriodo',
      ferias
    )
  }

  salvarDependente(funcionarioId: number, dependente: Dependente) {
    return this.http.post<Retorno<Dependente>>(
      this.baseUrl + 'v1/funcionarios/' + funcionarioId + '/dependentes',
      dependente
    )
  }

  salvarIntermitente(funcionarioId: number, intermitente: Intermitente) {
    return this.http.post<Retorno<Intermitente>>(
      this.baseUrl + 'v1/funcionarios/' + funcionarioId + '/intermitente',
      intermitente
    )
  }
  //#endregion

  //#region puts
  alterarColaborador(colaborador: ColaboradorComVinculo): Observable<Retorno<ColaboradorComVinculo>> {
    return this.http.put<Retorno<ColaboradorComVinculo>>(
      this.baseUrl + 'v2/funcionarios',
      colaborador
    )
  }

  alterarColaboradorComVinculo(colaborador: ColaboradorComVinculo) {
    return this.http.put<Retorno<ColaboradorComVinculo>>(
      this.baseUrl + 'v1/funcionariosComVinculo',
      colaborador
    )
  }

  alterarColaboradorSemVinculo(colaborador: ColaboradorComVinculo) {
    return this.http.put<Retorno<ColaboradorComVinculo>>(
      this.baseUrl + 'v1/funcionariosSemVinculo',
      colaborador
    )
  }

  alterarPreliminar(preliminar: Preliminar): Observable<Retorno<Preliminar>> {
    return this.http.put<Retorno<Preliminar>>(
      this.baseUrl + 'v1/funcionarios/preliminares',
      preliminar
    )
  }

  alterarFerias(ferias: Ferias): Observable<Retorno<Ferias>> {
    return this.http.put<Retorno<Ferias>>(
      this.baseUrl + 'v1/funcionarios/feriasPeriodo',
      ferias
    )
  }

  alterarDependente(id: number, dependente: Dependente): Observable<Retorno<Dependente>> {
    return this.http.put<Retorno<Dependente>>(
      this.baseUrl + 'v1/funcionarios/' + id + '/dependentes',
      dependente
    )
  }

  alterarIntermitente(funcionarioId: number, intermitente: Intermitente): Observable<Retorno<Intermitente>> {
    return this.http.put<Retorno<Intermitente>>(
      this.baseUrl + 'v1/funcionarios/' + funcionarioId + '/intermitente',
      intermitente
    )
  }
  //#endregion

  //#region deletes
  excluirColaborador(id: number): Observable<Retorno<ColaboradorComVinculo>> {
    return this.http.delete<Retorno<ColaboradorComVinculo>>(
      this.baseUrl + 'v1/funcionarios/' + id
    )
  }

  excluirPreliminar(id: number): Observable<Retorno<Preliminar>> {
    return this.http.delete<Retorno<Preliminar>>(
      this.baseUrl + 'v1/funcionarios/preliminares/' + id
    )
  }

  excluirFerias(id): Observable<Retorno<Ferias>> {
    return this.http.delete<Retorno<Ferias>>(
      this.baseUrl + 'v1/funcionarios/feriasPeriodo/' + id
    )
  }

  excluirDependente(id: number, dependenteId: number): Observable<Retorno<Dependente>> {
    return this.http.delete<Retorno<Dependente>>(
      this.baseUrl + 'v1/funcionarios/' + id + '/dependentes/' + dependenteId
    )
  }

  excluirIntermitentePorFuncionario(funcionarioId: number, intermitenteId: number): Observable<Retorno<Intermitente>> {
    return this.http.delete<Retorno<Intermitente>>(
      this.baseUrl + 'v1/funcionarios/' + funcionarioId + '/intermitente/' + intermitenteId
    )
  }
  //#endregion

  //#region cadastro temporário
  cadastrarPreliminarTemporario(funcionarioPreliminar: Preliminar) {
    return localStorage.setItem(
      this.STR_PRELIMINAR, this.criptografiaService.encriptar(
        JSON.stringify(funcionarioPreliminar)
      )
    )
  }

  pegarPreliminarTemporario() {
    return JSON.parse(
      this.criptografiaService.descriptar(
        localStorage.getItem(this.STR_PRELIMINAR)
      )
    )
  }
  //#endregion


  carregarDepartamentos(): Observable<Departamento[]> {
    return this.departamentoService.pegarLista().pipe(
      map(retorno => retorno.retorno),
      catchError(error => {
        this.mensagemService.showErroApi(error);
        return throwError(() => error); // Re-throw the error so that the caller can handle it
      })
    );
  }

  carregarCargos(): Observable<Cargo[]> {
    return this.cargosService.pegarLista().pipe(
      map(retorno => retorno.retorno),
      catchError(error => {
        this.mensagemService.showErroApi(error);
        return throwError(() => error);
      })
    );
  }

  carregarTurnos(): Observable<Turno[]> {
    return this.turnosService.pegarLista().pipe(
      map(retorno => retorno.retorno),
      catchError(error => {
        this.mensagemService.showErroApi(error);
        return throwError(() => error);
      })
    );
  }

  carregarLotacoesTributarias(): Observable<LotacaoTributaria[]> {
    return this.lotacaoTributariaService.pegarLista().pipe(
      map(retorno => retorno.retorno),
      catchError(error => {
        this.mensagemService.showErroApi(error);
        return throwError(() => error);
      })
    );
  }

}
