import { Injectable } from '@angular/core'
import * as Crypto from 'crypto-js'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class CriptografiaService {

  constructor() {
  }

  encriptar(conteudo: string): any {
    if (conteudo !== null && conteudo !== undefined && conteudo !== '') {
      return Crypto.AES.encrypt(conteudo, environment.criptografiaChave).toString()
    } else {
      return null
    }
  }

  descriptar(textoEncriptado: string): string {
    if (textoEncriptado !== null && textoEncriptado !== undefined && textoEncriptado !== '') {
      const bytes  = Crypto.AES.decrypt(textoEncriptado, environment.criptografiaChave)
      return bytes.toString(Crypto.enc.Utf8)
    } else {
      return null
    }
  }
}
