<span *ngIf="!modalAviso">
  <div class="modal-header">
    <h4 class="modal-title">Empresas</h4>
  </div>
  <div class="modal-body">
    <p-table #dt styleClass="p-datatable-sm" [scrollable]="true" scrollHeight="350px" [rowHover]="true"
    [value]="empresas" dataKey="id" sortField="nome" [globalFilterFields]="['nome']">

      <ng-template pTemplate="header">
        <tr>
          <th>Nome</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="caption">
        <div class="container">
          <div class="row justify-content-between">

            <div class="col-12 align-items: text-right">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" class="custom-control-input" id="ativas" name="radioEmpresas"
                  (click)="carregarEmpresas()" checked>
                <label class="custom-control-label" for="ativas">Ativas</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" class="custom-control-input" id="inativas" name="radioEmpresas"
                  (click)="carregarEmpresasInativas()">
                <label class="custom-control-label" for="inativas">Inativas</label>
              </div>
              <span class="p-input-icon-right ml-auto">
                <i class="pi pi-search"></i>
                <input pInputText type="text" #filter (input)="dt.filterGlobal($event.target.value, 'contains')"
                  placeholder="Pesquisa" />
              </span>
              <button pButton class="p-button-outlined p-button-secondary" icon="pi pi-times"
                (click)="clear(dt)"></button>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template pTemplate="body" let-empresas>
        <tr>
          <td style="cursor: pointer;" (click)="selecionarEmpresa(empresas)">{{ empresas.nome }}</td>
        </tr>
      </ng-template>

    </p-table>
  </div>
  <div class="modal-footer footer-grid justify-content space-between">
    <button type="button" class="btn btn-light btn-sm float-right" [routerLink]="['/empresa/0', verboIncluir]"
      (click)="bsModalRef.hide()">Empresa +</button>
    <button type="button" class="btn btn-light btn-sm float-right" (click)="fecharModal()">Fechar</button>
  </div>

</span>

<span *ngIf="modalAviso">
  <div class="modal-header header-grid">
    <h4 class="modal-title">Atenção</h4>
  </div>

  <div class="modal-body">
    Esta empresa não tem estabelecimento cadastrado. É necessário ter pelo menos um, você será direcionado agora à inclusão de estabelecimentos.
  </div>
  <div class="modal-footer footer-grid justify-content space-between">

    <button type="button" class="btn btn-light btn-sm float-right" (click)="fecharModal()">Continuar Cadastro</button>
  </div>
</span>
