import { Component, OnInit } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'
import { MensagemService } from 'src/app/core/services/mensagem.service'
import { TermoService } from 'src/app/core/services/termo.service'

@Component({
  selector: 'app-termo',
  templateUrl: './termo.component.html'
})
export class TermoComponent implements OnInit {
  podeFechar: boolean
  termo: string

  constructor(protected bsModalRef: BsModalRef, private termoService: TermoService,
              private mensagemService: MensagemService, private autenticacaoService: AutenticacaoService) { }

  ngOnInit() {
    this.termoService.pegarTermo().subscribe({
      next: (termo: any) => {
        this.termo = termo.contrato
      },
      error: (error) => this.mensagemService.showErroApi(error)
    })
  }

  aceitarTermo() {
    this.termoService.aceitarTermo().subscribe({
      next: () => {
        this.autenticacaoService.usuarioAceitouTermoContrato()
        this.bsModalRef.hide()
      },
      error: (error) => this.mensagemService.showErroApi(error)
    })
  }
}
