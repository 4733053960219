// Angular
import { Component, Input, OnInit } from '@angular/core'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'
import { Usuario } from 'src/app/core/models/autenticacao/Usuario'

@Component({
  selector: 'app-user-profile4',
  templateUrl: './user-profile4.component.html',
})
export class UserProfile4Component implements OnInit {
  // Public properties
  user$: Usuario

  @Input() avatar = true
  @Input() greeting = true
  @Input() badge: boolean
  @Input() icon: boolean

  constructor(private autenticacaoService: AutenticacaoService) {
  }

  ngOnInit(): void {
    this.user$ = this.autenticacaoService.pegarUsuario()
  }

  logout() {
    this.autenticacaoService.deslogar()
  }
}
