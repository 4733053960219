export class Usuario {
    constructor(clienteId: number, nome: string, permissoes: string[], email: string, telefone: string,
                emprego: string, empresa: string, logo: string, linkSuporte: string, linkSite: string,
                representante: string, representanteTelefone: string, temTermoPraAceitar: boolean,
                passouValidadeTermo: boolean, preCliente: boolean) {
        this.clienteId = clienteId
        this.nome = nome
        this.permissoes = permissoes
        this.email = email
        this.telefone = telefone
        this.emprego = emprego
        this.empresa = empresa
        this.logo = logo
        this.linkSuporte = linkSuporte
        this.linkSite = linkSite
        this.representante = representante
        this.representanteTelefone = representanteTelefone
        this.temTermoPraAceitar = temTermoPraAceitar
        this.passouValidadeTermo = passouValidadeTermo
        this.preCliente = preCliente
    }

    clienteId: number
    nome: string
    permissoes: string[]
    email: string
    telefone: string
    emprego: string
    empresa: string
    logo: string
    linkSuporte: string
    linkSite: string
    representante: string
    representanteTelefone: string
    temTermoPraAceitar: boolean
    passouValidadeTermo: boolean
    preCliente: boolean
}
