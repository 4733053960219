import { Injectable } from '@angular/core'
import { BaseService } from 'src/app/core/shared/services/base.service'
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http'
import { formatDate } from '@angular/common'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { environment } from 'src/environments/environment'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'
import { Retorno } from 'src/app/core/models/retorno'
import { Banco } from 'src/app/views/pages/compartilhada/conta-corrente/shared/banco.model'
import { ContraPartida } from 'src/app/core/models/conciliacaoBancaria/contraPartida'
import { HistoricoBancario } from 'src/app/core/models/historicoBancario'
import { ImportacaoOfx } from 'src/app/core/models/conciliacaoBancaria/importacaoOfx'
import { GerarConciliacaoBancariaOfx } from 'src/app/core/models/conciliacaoBancaria/gerarConciliacaoBancariaOfx'
import { GerarConciliacaoBancariaExcel } from 'src/app/core/models/conciliacaoBancaria/gerarConciliacaoBancariaExcel'
import { ImportacaoExcel } from 'src/app/core/models/conciliacaoBancaria/importacaoExcel'

@Injectable({
  providedIn: 'root'
})
export class ConciliacaoBancariaService extends BaseService {
  baseUrl = environment.apiUrl
  formatoData = 'MM-dd-yyyy'
  localData = 'pt-BR'

  constructor(protected http: HttpClient, protected variaveisContextoService: VariaveisContextoService) {
    super(variaveisContextoService)
  }

  //#region Vínculo
  pegarContraPartidas(contaCorrenteId: number): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId + '/conciliacoes-bancarias/contas-corrente/' + contaCorrenteId + '/contra-partidas')
  }

  salvarContraPartida(contraPartida: ContraPartida) {
    return this.http.post(this.baseUrl + 'v1/conciliacoes-bancarias/contra-partidas', contraPartida)
  }
  //#endregion

  //#region Históricos
  pegarHistoricos(bancoId: string): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId + '/conciliacoes-bancarias/bancos/' + bancoId + '/historicos')
  }

  pegarHistorico(id: string): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/empresas/' + this.empresaId + '/conciliacoes-bancarias/historicos/' + id)
  }

  excluirHistorico(id: string) {
    return this.http.delete(this.baseUrl + 'v1/empresas/' + this.empresaId + '/conciliacoes-bancarias/historicos/' + id)
  }

  salvarHistorico(historico: HistoricoBancario) {
    this.preencherEmpresaId()
    historico.empresaId = this.empresaId
    return this.http.post(this.baseUrl + 'v1/conciliacoes-bancarias/historicos', historico)
  }

  alterarHistorico(historico: HistoricoBancario) {
    this.preencherEmpresaId()
    historico.empresaId = this.empresaId
    return this.http.put(this.baseUrl + 'v1/conciliacoes-bancarias/historicos', historico)
  }
  //#endregion

  //#region Extrato
  pegarConciliacoesBancarias(estabelecimentoId: number, contaCorrenteId: number, dataInicial: Date, dataFinal: Date): Observable<Retorno<any>> {
    let params = new HttpParams()
    params = params.append('estabelecimentoId', estabelecimentoId.toString())
    params = params.append('contaCorrenteId', contaCorrenteId.toString())
    params = params.append('dataInicial', formatDate(dataInicial, this.formatoData, this.localData))
    params = params.append('dataFinal', formatDate(dataFinal, this.formatoData, this.localData))

    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/conciliacoes-bancarias', { observe: 'response', params})
    .pipe(map(resposta => resposta.body))
  }

  pegarConciliacoesBancariaOfx(estabelecimentoId: number, contaCorrenteId: number, dataInicial: Date, dataFinal: Date): Observable<Retorno<any>> {
    let params = new HttpParams()
    params = params.append('estabelecimentoId', estabelecimentoId.toString())
    params = params.append('contaCorrenteId', contaCorrenteId.toString())
    params = params.append('dataInicial', formatDate(dataInicial, this.formatoData, this.localData))
    params = params.append('dataFinal', formatDate(dataFinal, this.formatoData, this.localData))

    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/conciliacoes-bancarias/ofx', { observe: 'response', params})
    .pipe(map(resposta => resposta.body))
  }

  excluirConciliacaoBancaria(conciliacaoBancariaId: number) {
    return this.http.delete(this.baseUrl + 'v1/conciliacoes-bancarias/' + conciliacaoBancariaId)
  }

  excluirListaConciliacoes(ids: number[]) {
    const options = { headers: new HttpHeaders({'Content-Type': 'application/json'}), body: { ids} }
    return this.http.delete(this.baseUrl + 'v1/conciliacoes-bancarias', options)
  }

  importarArquivoOfx(importacaoOfx: ImportacaoOfx) {
    return this.http.post(this.baseUrl + 'v1/conciliacoes-bancarias/ofx/importacao', importacaoOfx)
  }

  gerarConciliacaoBancariaOfx(gerarConciliacaoBancaria: GerarConciliacaoBancariaOfx) {
    this.preencherEmpresaId()
    gerarConciliacaoBancaria.empresaId = this.empresaId
    return this.http.post(this.baseUrl + 'v1/conciliacoes-bancarias/ofx/gerar', gerarConciliacaoBancaria)
  }

  pegarConciliacoesBancariaExcel(estabelecimentoId: number, dataInicial: Date, dataFinal: Date): Observable<Retorno<any>> {
    let params = new HttpParams()
    params = params.append('estabelecimentoId', estabelecimentoId.toString())
    params = params.append('dataInicial', formatDate(dataInicial, this.formatoData, this.localData))
    params = params.append('dataFinal', formatDate(dataFinal, this.formatoData, this.localData))

    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/conciliacoes-bancarias/excel', { observe: 'response', params})
    .pipe(map(resposta => resposta.body))
  }

  importarArquivoExcel(importacaoExcel: ImportacaoExcel) {
    return this.http.post(this.baseUrl + 'v1/conciliacoes-bancarias/excel/importacao', importacaoExcel)
  }

  gerarConciliacaoBancariaExcel(gerarConciliacaoBancaria: GerarConciliacaoBancariaExcel) {
    this.preencherEmpresaId()
    gerarConciliacaoBancaria.empresaId = this.empresaId
    return this.http.post(this.baseUrl + 'v1/conciliacoes-bancarias/excel/gerar', gerarConciliacaoBancaria)
  }
  //#endregion
}
