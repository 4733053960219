// Angular
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core'
// RxJS
import { Subscription } from 'rxjs'
// Object-Path
import * as objectPath from 'object-path'
// Layout
import { LayoutConfigService, MenuConfigService, PageConfigService } from 'src/app/core/_base/layout'
import { HtmlClassService } from 'src/app/views/theme/html-class.service'
import { LayoutConfig } from 'src/app/core/_config/layout.config'
import { PageConfig } from 'src/app/core/_config/page.config'
// User permissions
import { NgxPermissionsService } from 'ngx-permissions'

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BaseComponent implements OnInit, OnDestroy {
  // Public variables
  selfLayout = 'default'
  asideSelfDisplay: true
  contentClasses = ''
  contentContainerClasses = ''
  subheaderDisplay = true
  contentExtended: false

  // Private properties
  private unsubscribe: Subscription[] = [] // Read more: => https://brianflove.com/2016/12/11/anguar-2-unsubscribe-observables/


  /**
   * Component constructor
   *
   * param layoutConfigService: LayoutConfigService
   * param menuConfigService: MenuConfigService
   * param pageConfigService: PageConfigService
   * param htmlClassService: HtmlClassService
   * param store
   * param permissionsService
   */
  constructor(
    private layoutConfigService: LayoutConfigService,
    private menuConfigService: MenuConfigService,
    private pageConfigService: PageConfigService,
    private htmlClassService: HtmlClassService,
    private permissionsService: NgxPermissionsService) {
    // register configs by demos
    this.layoutConfigService.loadConfigs(new LayoutConfig().configs)
    this.menuConfigService.loadConfigs()
    this.pageConfigService.loadConfigs(new PageConfig().configs)

    // setup element classes
    this.htmlClassService.setConfig(this.layoutConfigService.getConfig())

    const subscription = this.layoutConfigService.onConfigUpdated$.subscribe(layoutConfig => {
      // reset body class based on global and page level layout config, refer to html-class.service.ts
      document.body.className = ''
      this.htmlClassService.setConfig(layoutConfig)
    })
    this.unsubscribe.push(subscription)
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    const config = this.layoutConfigService.getConfig()
    // Load UI from Layout settings
    this.selfLayout = objectPath.get(config, 'self.layout')
    this.asideSelfDisplay = objectPath.get(config, 'aside.self.display')
    this.subheaderDisplay = objectPath.get(config, 'subheader.display')
    this.contentClasses = this.htmlClassService.getClasses('content', true).toString()
    this.contentContainerClasses = this.htmlClassService.getClasses('content_container', true).toString()
    this.contentExtended = objectPath.get(config, 'content.extended')

    // let the layout type change
    const subscription = this.layoutConfigService.onConfigUpdated$.subscribe(cfg => {
      setTimeout(() => {
        this.selfLayout = objectPath.get(cfg, 'self.layout')
      })
    })
    this.unsubscribe.push(subscription)
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    this.unsubscribe.forEach(sb => sb.unsubscribe())
    // https://www.npmjs.com/package/ngx-permissions
    this.permissionsService.flushPermissions()
  }
}
