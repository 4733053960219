// Angular
import { Component, OnInit } from '@angular/core'
// Layout
import { OffcanvasOptions } from 'src/app/core/_base/layout'
import { Usuario } from 'src/app/core/models/autenticacao/Usuario'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'

@Component({
  selector: 'app-quick-user-panel',
  templateUrl: './quick-user-panel.component.html',
  styleUrls: ['./quick-user-panel.component.scss']
})
export class QuickUserPanelComponent implements OnInit {
  user$: Usuario
  qtdeMensagens: number
  notificacoes: boolean
  inLineSvgEmail: string
  inLineSvgCall: string
  inLineSvgSite: string
  inLineSvgContact: string

  offcanvasOptions: OffcanvasOptions = {
    overlay: true,
    baseClass: 'offcanvas',
    placement: 'right',
    closeBy: 'kt_quick_user_close',
    toggleBy: 'kt_quick_user_toggle'
  }

  constructor(private autenticacaoService: AutenticacaoService) {

  }

  ngOnInit(): void {
    this.user$ = this.autenticacaoService.pegarUsuario()
    this.qtdeMensagens = 0
    this.notificacoes = false
    this.inLineSvgEmail = './assets/media/svg/icons/Communication/Mail-at.svg'
    this.inLineSvgCall = './assets/media/svg/icons/Communication/Call1.svg'
    this.inLineSvgSite = './assets/media/svg/icons/Devices/Display1.svg'
    this.inLineSvgContact = './assets/media/svg/icons/Communication/Add-user.svg'
    // Caso queira colocar o ícone do e-mail com uma bolinha indicando notificação, utilizar a linha a baixo
    // this.inLineSvgEmail = './assets/media/svg/icons/Communication/Mail-notification.svg'
  }

  logout() {
    this.autenticacaoService.deslogar()
  }
}
