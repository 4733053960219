<!-- begin:: Header Topbar -->
<ng-container>

  <ng-container *ngIf="sistemasDisplay">
    <!--begin: Sistemas -->
    <div class="topbar-item">
      <app-sistemas
        ktToggle
        [options]="toggleOptions"
        [routerLink]="['/dashboard']"
      ></app-sistemas>
    </div>
    <!--end: Sistemas -->
  </ng-container>

  <ng-container *ngIf="relatorioRapidoDisplay">
    <!--begin: Relatório rápido -->
    <div class="topbar-item" ngbTooltip="Relatórios">
      <app-relatorio-rapido
        [bgImage]="'./assets/media/misc/bg-1.jpg'"
        [icon]="'./assets/media/svg/icons/Devices/Printer.svg'"
        [useSVG]="true"
      ></app-relatorio-rapido>
    </div>
    <!--end: Relatório rápido -->
  </ng-container>

  <ng-container *ngIf="notasLancamentoDisplay">
    <!--begin: Notas de lançamento -->
    <div class="topbar-item" ngbTooltip="Notas de lançamento">
      <app-notas-lancamento
        [bgImage]="'./assets/media/misc/bg-1.jpg'"
        [icon]="'./assets/media/svg/icons/Files/File.svg'"
        [useSVG]="true"
      ></app-notas-lancamento>
    </div>
    <!--end: Notas de lançamento -->
  </ng-container>

  <ng-container *ngIf="chatDisplay && linkSuporte && linkSuporte !== ''">
    <!--begin: Chat -->
    <div class="topbar-item" ngbTooltip="Suporte">
      <app-chat
        [bgImage]="'./assets/media/misc/bg-1.jpg'"
        [icon]="'./assets/media/svg/icons/Communication/Chat1.svg'"
        [useSVG]="true"
        [linkSuporte]="linkSuporte"
      ></app-chat>
    </div>
    <!--end: Chat -->
  </ng-container>

  <ng-container *ngIf="settingsDisplay">
    <!--begin: Chat -->
    <div class="topbar-item" ngbTooltip="Configurações">
      <app-configuracoes
        [bgImage]="'./assets/media/misc/bg-1.jpg'"
        [icon]="'./assets/media/svg/icons/General/Settings-2.svg'"
        [useSVG]="true"
        [routerLink]="linkSettings"
      ></app-configuracoes>
    </div>
    <!--end: Chat -->
  </ng-container>

  <ng-container *ngIf="languagesDisplay">
    <!--begin: Language bar -->
    <app-language-selector></app-language-selector>
    <!--end: Language bar -->
  </ng-container>

  <ng-container *ngIf="userDisplay">
    <ng-container *ngIf="userLayout === 'dropdown'">
      <app-user-profile [userDropdownStyle]="userDropdownStyle"></app-user-profile>
    </ng-container>
    <ng-container *ngIf="userLayout === 'offcanvas'">
      <!--begin: User bar -->
      <app-user-profile4></app-user-profile4>
      <!--end: User bar -->
    </ng-container>
  </ng-container>
</ng-container>
<!-- end:: Header Topbar -->
