import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { TermoComponent } from 'src/app/views/partials/layout/termo/termo.component'
import { Usuario } from 'src/app/core/models/autenticacao/Usuario'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'
import { MensagemService } from 'src/app/core/services/mensagem.service'

@Injectable({
  providedIn: 'root'
})
export class AutenticacaoGuard implements CanActivate {
  modalRef: BsModalRef

  constructor(private authService: AutenticacaoService, private router: Router,
              private modalService: BsModalService, private mensagemService: MensagemService) {}

  canActivate(): boolean {
    if (this.authService.logado()) {
      const usuario: Usuario = this.authService.pegarUsuario()
      if (usuario.permissoes.some(p => p === 'Admin')) {
        if (usuario.temTermoPraAceitar) {
          this.modalRef = this.modalService.show(TermoComponent, { ignoreBackdropClick: usuario.passouValidadeTermo, initialState: { podeFechar: !usuario.passouValidadeTermo } })
        }
      } else if (usuario.temTermoPraAceitar && usuario.passouValidadeTermo) {
        this.mensagemService.showAviso('O Administrador do sistema precisa aceitar os termos de uso do sistema.')
        this.router.navigate(['/login'])
        return false
      }

      return true
    }
    this.router.navigate(['/login'])
    return false
  }

}
