import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { JwtHelperService } from '@auth0/angular-jwt'
import { map } from 'rxjs/operators'
import { environment } from 'src/environments/environment'
import { EsqueceuSenha } from 'src/app/core/models/esqueceuSenha'
import { Login } from 'src/app/core/models/login'
import { ResetarSenha } from 'src/app/core/models/resetarSenha'
import { Usuario } from 'src/app/core/models/autenticacao/Usuario'
import { CriptografiaService } from 'src/app/core/shared/services/criptografia.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { TokenDecodificado } from 'src/app/core/models/autenticacao/TokenDecodificado'
import { Buffer } from 'buffer'

@Injectable({
  providedIn: 'root'
})

export class AutenticacaoService {
  private urlAcesso = environment.controleUrl
  private STR_TOKEN = 'TOKEN'
  private STR_USER = 'USER'
  jwtHelper = new JwtHelperService()

  constructor(private criptografiaService: CriptografiaService,
    protected http: HttpClient,
    private router: Router,
    private variaveisContextoService: VariaveisContextoService
  ) { this.router.routeReuseStrategy.shouldReuseRoute = () => false }

  logar(model: Login) {
    return this.http.post(this.urlAcesso + 'Acesso/Login', model)
      .pipe(
        map((resposta: any) => {
          if (resposta) {
            localStorage.setItem(this.STR_TOKEN, resposta.token)
            this.salvarUsuario(resposta.user)
//            console.log(resposta.user)
          }
        })
      )
  }

  esqueceuSenha(esqueceuSenha: EsqueceuSenha) {
    return this.http.post(this.urlAcesso + 'Acesso/EsqueceuSenha', esqueceuSenha)
  }

  resetarSenha(resetarSenha: ResetarSenha) {
    return this.http.post(this.urlAcesso + 'Acesso/ResetarSenha', resetarSenha)
  }

  logado(): boolean {
    const token = localStorage.getItem(this.STR_TOKEN)
    const logado: boolean = token != null && !this.jwtHelper.isTokenExpired(token)
    let empresaNaoEhNula: boolean
    let estabelecimentoEhNulo: boolean

    this.variaveisContextoService.empresaAtual.subscribe((empresa) => {
      empresaNaoEhNula = empresa !== null
    })

    if (!logado && empresaNaoEhNula) {
      this.variaveisContextoService.mudarEmpresa(null)
    }

    this.variaveisContextoService.estabelecimentoAtual.subscribe((estabelecimento) => {
      estabelecimentoEhNulo = estabelecimento !== null
    })

    if (!logado && estabelecimentoEhNulo) {
      this.variaveisContextoService.mudarEstabelecimento(null)
    }

    return logado
  }

  private salvarUsuario(dadosUsuario: Usuario) {
    this.variaveisContextoService.empresaAtual.subscribe((empresa) => {
      if (empresa !== null && dadosUsuario !== null && dadosUsuario !== undefined && dadosUsuario.clienteId !== empresa.clienteId) {
        this.variaveisContextoService.mudarEmpresa(null)
      }
    })

    this.variaveisContextoService.estabelecimentoAtual.subscribe((estabelecimento) => {
      if (estabelecimento !== null) {
        this.variaveisContextoService.mudarEstabelecimento(null)
      }
    })

    const tokenDecodificado: TokenDecodificado = this.decodificarToken()

    const usuario: Usuario = new Usuario(
      dadosUsuario.clienteId, dadosUsuario.nome,
      Array.isArray(tokenDecodificado.role) ? tokenDecodificado.role : [tokenDecodificado.role],
      dadosUsuario.email, dadosUsuario.telefone, dadosUsuario.emprego, dadosUsuario.empresa,
      dadosUsuario.logo, dadosUsuario.linkSuporte, dadosUsuario.linkSite, dadosUsuario.representante,
      dadosUsuario.representanteTelefone, dadosUsuario.temTermoPraAceitar, dadosUsuario.passouValidadeTermo,
      dadosUsuario.preCliente)

    localStorage.setItem(this.STR_USER, this.criptografiaService.encriptar(JSON.stringify(usuario)))
  }

  pegarUsuario(): Usuario {
    return JSON.parse(this.criptografiaService.descriptar(localStorage.getItem(this.STR_USER)))
  }

  usuarioAceitouTermoContrato() {
    const usuario: Usuario = this.pegarUsuario()
    usuario.temTermoPraAceitar = false
    this.salvarUsuario(usuario)
  }

  deslogar(): void {
    localStorage.removeItem(this.STR_USER)
    localStorage.removeItem(this.STR_TOKEN)

    this.variaveisContextoService.mudarEmpresa(null)
    this.variaveisContextoService.mudarEstabelecimento(null)

    this.router.navigate(['/login'])
  }

  token(): string { return localStorage.getItem(this.STR_TOKEN) }

  private decodificarToken(): TokenDecodificado {
    const token = this.token()
    return JSON.parse(Buffer.from(token.split('.')[1], "base64").toString())
  }

}
