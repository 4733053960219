import { BaseModel } from 'src/app/core/shared/models/base-model.model'

export class Cargo extends BaseModel {
  constructor(
    public id?: number,
    public descricao?: string,
    public funcao?: string,
    public empresaId?: number,
    public cboId?: number,
    public cbo?: string
  ) { super() }

  static fromJson(jsonDados: any): Cargo {
    return Object.assign(new Cargo(), jsonDados)
  }
}
