// Angular
import { Component, OnInit } from '@angular/core'
// Layout
import { HtmlClassService } from 'src/app/views/theme/html-class.service'
// Object-Path
import * as objectPath from 'object-path'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
})
export class FooterComponent implements OnInit {
  // Public properties
  today: number = Date.now()
  footerClasses = ''
  footerContainerClasses = ''
  empresa = ''
  representante = ''
  link = ''

  /**
   * Component constructor
   *
   * @param uiClasses: HtmlClassService
   */
  constructor(private uiClasses: HtmlClassService, private autenticacaoService: AutenticacaoService) {
  }

  /**
   * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
   */

  /**
   * On init
   */
  ngOnInit(): void {
    this.footerClasses = this.uiClasses.getClasses('footer', true).toString()
    this.footerContainerClasses = this.uiClasses.getClasses('footer_container', true).toString()
    const usuario = this.autenticacaoService.pegarUsuario()
    this.empresa = usuario.empresa
    this.representante = usuario.representante
    this.link = usuario.linkSite
  }
}
