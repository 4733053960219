<div placement="bottom-right">
  <div class="topbar-item">
    <div class="btn btn-icon btn-clean btn-lg mr-1" (click)="abrirOpcoesSuporte()">
      <ng-container *ngIf="useSVG">
        <span matTooltip="{{sistema.tooltip}}" flow="down"  class="svg-icon svg-icon-xl svg-icon-primary">
          <i class="fas" [inlineSVG]="icon"></i>
        </span>
      </ng-container>
      <ng-container *ngIf="!useSVG">
        <i [ngClass]="icon"></i>
      </ng-container>
    </div>
  </div>
</div>

<ng-template #modalSuporte>
  <div class="modal-header d-flex justify-content-center">
    <h4>Suporte</h4>
  </div>
  <div class="modal-body align-items-center text-center">
    <i
      class="flaticon2-chat-1 text-digibyte"
      role="button" (click)="abrirChat()"
    >  Abrir chat</i> <br><br><br>
    <i
      class="flaticon-download text-digibyte"
      role="button" (click)="baixarChat()"
    >  Baixar suporte</i>
  </div>
  <div class="modal-footer"></div>
</ng-template>
