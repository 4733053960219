import { Injectable, Injector } from '@angular/core'
import { Observable } from 'rxjs'
import { Retorno } from 'src/app/core/models/retorno'
import { BaseApiService } from 'src/app/core/shared/services/base-api.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service';
import { environment } from 'src/environments/environment'
import { Cargo } from './cargo.model'

@Injectable({
  providedIn: 'root'
})
export class CargosService extends BaseApiService<Cargo> {
  baseUrl: string = environment.apiUrl

  constructor(protected injector: Injector, protected contexto: VariaveisContextoService) {
    super('v1/cargos/', injector, Cargo.fromJson, contexto)
  }

  pegarCBOs(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/cargos/cbos')
  }
}
