<div class="modal-header">
  <div style="flex-direction:column;">
    <div>
      <span><strong>Movimento:</strong> {{ data | date: 'MMMM y' }}</span>
    </div>
    <div>
      <span><strong>Estabelecimento:</strong> {{ estabelecimentoNome }}</span>
    </div>
    <div>
      <span><strong>Conta Corrente:</strong> {{ contaCorrenteNome }}</span>
    </div>
  </div>
</div>

<div class="modal-body">
  <div>
    <span>Selecione o tipo de arquivo para importar:</span>
  </div>
  <br>
  <div class="radio-buttons d-flex justify-content-around align-items-center col-md-12">
    <label>
      <input type="radio" name="fileType" value="ofx" [(ngModel)]="fileType"> OFX
    </label>
    <label>
      <input type="radio" name="fileType" value="excel" [(ngModel)]="fileType"> Excel
    </label>
  </div>

  <!-- Seção para Excel -->
  <div *ngIf="fileType === 'excel'">
    <div class="col-md-12 my-5">
      <div>
        <label>Arquivo EXCEL</label>
        <i tooltip="São permitidos apenas arquivos no formato {{ listaExtensoes.join(', ')}}"
          class="ml-1 fa fa-info-circle fa-1" aria-hidden="true"></i>

        <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [accept]="accept">
          <ngx-dropzone-label>Arraste seu extrato EXCEL ou clique aqui !</ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let arquivo of arquivos" [removable]="true" (removed)="onRemove(arquivo)">
            <ngx-dropzone-label>{{ arquivo.name }}</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>
    <div class="col-md-12">
      <div class="row justify-content-between">
        <div class="col-md-6">
          <button class="btn btn-outline-digibyte btn-sm btn-block" (click)="importar()">Importar EXCEL</button>
        </div>
        <div *ngIf="arquivos !== null && arquivos !== undefined && arquivos.length === 1" class="col text-right">
          <button class="btn btn-outline-digibyte btn-sm btn-block" (click)="abrirModalColunas()">Indicar colunas
            EXCEL</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Seção para OFX -->
  <div *ngIf="fileType === 'ofx'">
    <div class="col-md-12 my-5">
      <div>
        <label>Arquivo OFX</label><i tooltip="São permitidos apenas arquivos no formato OFX"
          class="ml-1 fa fa-info-circle fa-1" aria-hidden="true"></i>
        <ngx-dropzone (change)="onSelect($event)" [multiple]="false" [accept]="accept">
          <ngx-dropzone-label>Arraste seu extrato OFX ou clique aqui !</ngx-dropzone-label>
          <ngx-dropzone-preview *ngFor="let arquivo of arquivos" [removable]="true" (removed)="onRemove(arquivo)">
            <ngx-dropzone-label>{{ arquivo.name }}</ngx-dropzone-label>
          </ngx-dropzone-preview>
        </ngx-dropzone>
      </div>
    </div>
    <div class="col-md-6">
      <button class="btn btn-outline-digibyte btn-sm btn-block" (click)="importar()">Importar OFX</button>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-outline-digibyte" (click)="closeModal()">Cancelar</button>
</div>

<!-- Início Modal Colunas  -->
<ng-template #modalColunas>
  <div class="modal-content">
    <div class="modal-header">
      <h4>Indique as colunas</h4>
    </div>
    <div class="modal-body" style="height: 400px; overflow-y: auto;">
      <form [formGroup]="colunasForm">
        <div class="row">
          <div class="form-group col-md-2">
            <span>Data</span>
            <ng-select formControlName="data" [items]="colunas" [clearable]="false"
              notFoundText="Nenhuma coluna encontrada" loadingText="Carregando...">
            </ng-select>
            <app-erro-campo ngDefaultControl [formControl]="colunasForm.get('data')">
            </app-erro-campo>
          </div>
          <div class="form-group col-md-2">
            <span>Valor</span>
            <ng-select formControlName="valor" [items]="colunas" [clearable]="false"
              notFoundText="Nenhuma coluna encontrada" loadingText="Carregando...">
            </ng-select>
            <app-erro-campo ngDefaultControl [formControl]="colunasForm.get('valor')">
            </app-erro-campo>
          </div>
          <div class="form-group col-md-2">
            <span>Histórico</span>
            <ng-select formControlName="historico" [items]="colunas" [clearable]="false"
              notFoundText="Nenhuma coluna encontrada" loadingText="Carregando...">
            </ng-select>
            <app-erro-campo ngDefaultControl [formControl]="colunasForm.get('historico')">
            </app-erro-campo>
          </div>
          <div class="form-group col-md-2">
            <span>Tipo</span>
            <ng-select formControlName="tipo" [items]="colunas" [clearable]="false"
              notFoundText="Nenhuma coluna encontrada" loadingText="Carregando...">
            </ng-select>
            <app-erro-campo ngDefaultControl [formControl]="colunasForm.get('tipo')">
            </app-erro-campo>
          </div>
          <div class="form-group col-md-2">
            <span>Conta corrente</span>
            <ng-select formControlName="contaCorrente" [items]="colunas" [clearable]="false"
              notFoundText="Nenhuma coluna encontrada" loadingText="Carregando...">
            </ng-select>
            <app-erro-campo ngDefaultControl [formControl]="colunasForm.get('contaCorrente')">
            </app-erro-campo>
          </div>
        </div>
      </form>

      <p-table [value]="colunas" [tableStyle]="{'min-width': '50rem'}" styleClass="p-datatable-sm" [scrollable]="true"
        scrollHeight="280px" [rowHover]="true">
        <ng-template pTemplate="header">
          <tr class="bg-digibyte text-light">
            <td *ngFor="let coluna of colunas">
              {{coluna}}
            </td>
          </tr>
        </ng-template>
        <ng-template pTemplate="body" let-product>
          <tr *ngFor="let linha of linhas">
            <td *ngFor="let celula of linha">
              {{celula}}
            </td>
          </tr>
        </ng-template>
      </p-table>

    </div>
    <div class="modal-footer">
      <div class="text-center float-right">
        <div class="btn-toolbar" role="toolbar">
          <div class="btn-toolbar justify-content-center" role="toolbar">
            <button type="button" class="btn btn-outline-digibyte btn-sm" (click)="modalRef.hide()">Fechar</button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>
<!-- Final Modal Colunas  -->
