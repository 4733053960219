export const environment = {
  production: true,
  criptografiaChave: '_!@#F2nT34aXe5($%)_',
  authTokenKey: 'authce9d77b308c149d5992a80073637e4d5',
  apiUrl: 'https://digibyteprod.azurewebsites.net/',
  apiUrlJwtToken: 'digibyteprod.azurewebsites.net',
  apiNfseUrl: 'https://nfedocs.azurewebsites.net/',
  apiNfseUrlJwtToken: 'nfedocs.azurewebsites.net',
  controleUrl: 'https://prodcontroledb.azurewebsites.net/api/',
  stimulsoftChave:
  "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHk3IYIl2tFztPisgU+MoBba72xblqNCJJABqsMOOWAiAIpRrv" +
  "mlV/g9id8gLhwqadyy6GLFEvribNolvGxO6HIHFmx5jOEEvJygid1HQCxcuZbftGrpNWVdepdnzKmc4HeIIhI1Hqgf" +
  "nfCMVt33+mrQ0Vq5rgptmrNGpyXD0doRtQlv19C4lekKcU1ePb7zYcS6H/1gtkSre5GCdZTLAiIgS8X77UJrmQyH1L" +
  "tPkJNkf9V7kKU5XkRP1OKLUwT53E+md4CWjjZ5yWB1SCPXErJieFdymhTuoDBvBkNpkecJpoK2bo045tESk+aVrexi" +
  "ivOnmxJc3nLZs9vsKfliyG5UCNN/9GtsPXCiS5IIZq5oVGCBSysuSa0LwZd8pBOr7IA2U2xmWEnvu9JwjTDnaWl7DX" +
  "Sa9SQCk1QyXmOYzcTM3vWb/G/gIWsgpGriwgoS7L7KPiq/+If4twfnoZD7v7SNhfwb2M6DqtC+A0vsUatsJc+04OK1" +
  "n0g8ZFFYtH+jK4c/09ietTpm5hSL+6xJw6PaySbeyv20qhH0spL3EK0/yw==",

  cnaeIbgeUrl: 'https://servicodados.ibge.gov.br/api/',
  urlConsultaWS: 'https://receitaws.com.br/v1/cnpj/',
  // https://devblogs.microsoft.com/premier-developer/angular-how-to-add-application-insights-to-an-angular-spa/
  // https://www.youtube.com/watch?v=ln3Cn8NZ4wM
  appInsights: {
    instrumentationKey: 'c4f0c262-2175-4704-b87a-65959cc576eb'
  },

}
