<div placement="bottom-right">
    <div class="topbar-item">
      <div class="btn btn-icon btn-clean btn-lg mr-1">
        <ng-container *ngIf="useSVG">
          <span matTooltip="{{sistema.tooltip}}" flow="down"  class="svg-icon svg-icon-xl svg-icon-primary">
            <i class="fas" [inlineSVG]="icon"></i>
          </span>
          <!-- <span  class="svg-icon svg-icon-xl svg-icon-primary" [inlineSVG]="icon"></span> -->
        </ng-container>
        <ng-container *ngIf="!useSVG">
          <i [ngClass]="icon"></i>
        </ng-container>
      </div>
    </div>
</div>
