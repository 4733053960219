import { BaseModel } from 'src/app/core/shared/models/base-model.model'

export class Departamento extends BaseModel {
  constructor(
    public id?: number,
    public empresaId?: number,
    public descricao?: string
  ) { super() }

  static fromJson(jsonDados: any): Departamento {
    return Object.assign(new Departamento(), jsonDados)
  }
}
