import { Injectable, Injector } from '@angular/core'
import { Observable } from 'rxjs'
import { Retorno } from 'src/app/core/models/retorno'
import { BaseApiService } from 'src/app/core/shared/services/base-api.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { LotacaoTributaria } from './lotacao-tributaria.model'


@Injectable({
  providedIn: 'root'
})
export class LotacaoTributariaService extends BaseApiService<LotacaoTributaria> {
  constructor(protected injector: Injector, protected contexto: VariaveisContextoService) {
    super('v1/lotacoes-tributarias/', injector, LotacaoTributaria.fromJson, contexto)
  }

  carregarLista(): Observable<Retorno<LotacaoTributaria[]>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<LotacaoTributaria[]>>(
      this.baseUrlEmpresa + this.empresaId + '/' + this.moduloUrl.slice(3)
    )
  }

  pegarTipo(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/lotacoes-tributarias/tipos')
  }

  pegarFpas(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/lotacoes-tributarias/fpas')
  }
}
