import { Injectable, Injector } from '@angular/core'
import { Observable } from 'rxjs'
import { Retorno } from 'src/app/core/models/retorno'
import { BaseApiService } from 'src/app/core/shared/services/base-api.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { Rubrica } from './rubricas.model'

@Injectable({
  providedIn: 'root'
})
export class RubricasService extends BaseApiService<Rubrica> {
  constructor(protected injector: Injector, protected contexto: VariaveisContextoService) {
    super('v1/rubricas/', injector, Rubrica.fromJson, contexto)
  }

  carregarLista(): Observable<Retorno<Rubrica[]>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<Rubrica[]>>(
      this.baseUrl + this.moduloUrl + 'empresas/' + this.empresaId
    )
  }

  pegarDescritivo(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + this.moduloUrl + 'empresas/' + this.empresaId + '/descritivo'
    )
  }

  pegarBasesPorRubrica(id: number): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/rubricas/' + id + '/bases'
    )
  }

  pegarRubricasPorEmpresaParaListaParaLancamentos(): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/rubricas/empresas/' + this.empresaId + '/lista-para-lancamentos-manuais')
  }

  pegarListaDescricaoRubricasPorEmpresa(): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/rubricas/empresas/' + this.empresaId + '/descritivo')
  }

  salvarFazBase(response) {
    return this.http.post(this.baseUrl + 'v1/rubricas/fazBase', response)
  }

  deletarFazBase(id: number) {
    return this.http.delete(this.baseUrl + 'v1/rubricas/bases/' + id)
  }

  pegarNaturezas(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/naturezas'
    )
  }

  pegarOrigens(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/origens'
    )
  }

  pegarRubricasIncCP(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/inc-cp'
    )
  }

  pegarRubricasIncFGTS(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/inc-fgts'
    )
  }

  pegarRubricasIncIRRF(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/inc-irrf'
    )
  }

  pegarRubricasIncSind(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/inc-sind'
    )
  }

  pegarTipos(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/tipos'
    )
  }

  pegarReferencias(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/referencias'
    )
  }

  pegarTiposProcessos(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/tipos-processos'
    )
  }

  pegarTiposExtensoesProcessos(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/rubricas/tipos-extensoes-processos'
    )
  }
}
