import { Component, ChangeDetectorRef, OnInit, OnChanges } from '@angular/core'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { MenuConfigService } from 'src/app/core/_base/layout'
import { Sistema } from 'src/app/core/_base/layout/models/sistema.enum'
import { Subscription, filter } from 'rxjs'


@Component({
  selector: 'app-nome-empresa',
  templateUrl: './nome-empresa.component.html'
})
export class NomeEmpresaComponent implements OnInit {
  private subs = new Subscription();
  dataMovimento: string = ''
  sistema: number
  nomeTitulo: string = ''

  constructor(
    private variaveisContextoService: VariaveisContextoService,
    private menuConfigService: MenuConfigService,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.sistema = this.menuConfigService.pegarSistemaAberto()
    this.subs.add(this.variaveisContextoService.empresaAtual.subscribe((empresa) => {
      if (empresa !== null && empresa !== undefined) {
        if (this.sistema === Sistema.Gerenciador) {
          if (sessionStorage.getItem('ID_ESCRITORIO') === '0') {
            this.nomeTitulo = 'Atenção: Contabilidade não cadastrada'
          } else {
            this.nomeTitulo = 'Contabilidade ' + empresa.nome
          }
        }
        else {
          this.nomeTitulo = empresa.nome
        }
      } else {
        this.nomeTitulo = 'Empresa não selecionada ou sem cadastro de empresas'
      }
      this.cd.markForCheck()
    }))
    return
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
