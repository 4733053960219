import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Retorno } from 'src/app/core/models/retorno'
import { BaseService } from 'src/app/core/shared/services/base.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'

@Injectable({
  providedIn: 'root'
})
export class EventosEsocialService extends BaseService {
  baseUrl = environment.apiUrl

  constructor(
    protected http: HttpClient,
    protected variaveisContextoService: VariaveisContextoService
  ) {
    super (variaveisContextoService)
  }

  processarESocial(): Observable<Retorno<any>> {
    return this.http.post<Retorno<any>>(this.baseUrl + 'v1/esocial/processar',  null)
  }

  enviarESocial(cliente): Observable<Retorno<any>> { // provisoriamente retirador para testes
    return this.http.post<Retorno<any>>(this.baseUrl + 'v1/esocial/enviar',  cliente)
  }

  consultarESocial(cliente): Observable<Retorno<any>> { // provisoriamente retirador para testes
    return this.http.post<Retorno<any>>(this.baseUrl + 'v1/esocial/consultar', cliente)
  }

  alterarStatus(status): Observable<Retorno<any>> {
    return this.http.post<Retorno<any>>(this.baseUrl + 'v1/esocial/alterarStatus', status)
  }

  baixarErrosESocial(erros): Observable<Retorno<any>> {
    this.preencherEmpresaId()
    return this.http.post<Retorno<any>>(this.baseUrl + 'v1/esocial/baixarErros/' + this.empresaId, erros)
  }

  baixarErrosLote(loteId: number, lote): Observable<Retorno<any>> {
    return this.http.post<Retorno<any>>(this.baseUrl + 'v1/esocial/baixarErroSelecionado/' + loteId, lote)
  }

  pegarEventos(): Observable<Retorno<any>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/esocial/empresas/' + this.empresaId + '/eventos'
    )
  }

  pegarEmpresas(mes: number, ano: number): Observable<Retorno<any>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/esocial/eventos/empresas' +
      '?mes=' + mes + '&ano=' + ano
    )
  }
  pegarEventosDaEmpresa(mes: number, ano: number, id: number): Observable<Retorno<any>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/esocial/eventos' + '?empresaId=' + id +
      '&mes=' + mes + '&ano=' + ano
    )
  }

  pegarEventosPorId(eventoId: number): Observable<Retorno<any>> {
    this.preencherEmpresaId()

    return this.http.get<Retorno<any>>(this.baseUrl + 'v1/esocial/eventos/' + eventoId
    )
  }

  pegarListaOpcoes(): Observable<Retorno<any>> {
    return this.http.get<Retorno<any>>(
      this.baseUrl + 'v1/esocial/eventos-lista-opcoes'
    )
  }

  zerarBase(empresaId: number): Observable<Retorno<any>> {
    return this.http.post<Retorno<any>>(this.baseUrl + 'v1/esocial/zerarBase', { empresaId })
  }
}
