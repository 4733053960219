import { Injectable } from '@angular/core';
import { AutenticacaoService } from '../../services/autenticacao.service';
import { Usuario } from 'src/app/core/models/autenticacao/Usuario'
import { Sistema } from '../../_base/layout/models/sistema.enum'

@Injectable({
  providedIn: 'root'
})
export class ModulosService {
  private _modulos: string[] = [];
  modulo: string[] = ['Contabil', 'Folha', 'Fiscal','Gerenciador']

  constructor(private authService: AutenticacaoService) {}

  get modulosLiberados(): string[] {
    this._modulos = [];
    const usuario: Usuario = this.authService.pegarUsuario()
    for (let i = 0; i < this.modulo.length; i++) {
      if (usuario.permissoes.some(p => p === this.modulo[i])) {
        this._modulos.push(this.modulo[i])
      }
    }

    return this._modulos;
  }

  set modulosLiberados(newValue: string[]) {
    this._modulos = newValue;
  }

  converterSistemaParaString(sistema: Sistema): string {
    if (sistema === null || sistema === undefined) {
      return null;
    }
    const mapeamentoSistemaParaString = {
      [Sistema.Contabil]: "Contabil",
      [Sistema.Folha]: "Folha",
      [Sistema.Fiscal]: "Fiscal",
      [Sistema.Gerenciador]: "Gerenciador"
    }
    return mapeamentoSistemaParaString[sistema]
  }

  converterStringParaSistema(sistemaStr: string): Sistema {
    const mapeamentoStringParaSistema = {
      "Contabil": Sistema.Contabil,
      "Folha": Sistema.Folha,
      "Fiscal": Sistema.Fiscal,
      "Gerenciador": Sistema.Gerenciador
    }
    return mapeamentoStringParaSistema[sistemaStr]
  }

}
