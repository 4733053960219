import { Injectable } from '@angular/core'
import { VariaveisContextoService } from './variaveis-contexto.service'

@Injectable({
  providedIn: 'root'
})
export class BaseService {
  empresaId: number
  estabelecimentoId: number

  constructor(protected variaveisContextoService: VariaveisContextoService) { }

  protected preencherEmpresaId() {
    this.variaveisContextoService.empresaAtual.subscribe(empresa => {
      if (empresa !== null && empresa !== undefined) {
        this.empresaId = empresa.id
      } else {
        this.empresaId = 0
      }
    })
  }

  protected preencherEstabelecimentoId() {
    this.variaveisContextoService.estabelecimentoAtual.subscribe(estabelecimento => {
      if (estabelecimento !== null && estabelecimento !== undefined) {
        this.estabelecimentoId = estabelecimento.id
      } else {
        this.estabelecimentoId = 0
      }
    })
  }

}
