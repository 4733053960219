import { HttpClient } from '@angular/common/http'
import { Injector } from '@angular/core'
import { Observable } from 'rxjs'
import { environment } from 'src/environments/environment'
import { Retorno } from '../../models/retorno'
import { BaseModel } from '../models/base-model.model'
import { BaseService } from './base.service'
import { VariaveisContextoService } from './variaveis-contexto.service'
import { ActivatedRoute } from '@angular/router'

export abstract class BaseApiService<T extends BaseModel> extends BaseService {
  routingsComEmpresaIdInicio: string[] = ['cargos', 'departamentos', 'lotacoes-tributarias', 'grupos-patrimonio', 'patrimonios', 'conta-corrente', 'fornecedores-clientes']
  routingsComEmpresaIdFinal: string[] = ['anuenios', 'lancamentoVT', 'turnos']
  routingsComEmpresasIdFinal: string[] = ['rubricas']
  routingsComEmpresaIdSemEmpresa: string[] = ['assinantes']
  baseUrl: string = environment.apiUrl
  baseUrlEmpresa: string = environment.apiUrl + 'v1/empresas/'
  empresaId: number = 0

  protected http: HttpClient
  protected route: ActivatedRoute

  constructor(
    protected moduloUrl: string,
    protected injector: Injector,
    protected jsonDadoParaModuloFn: (jsonDados: any) => T,
    protected variaveisContextoService: VariaveisContextoService
  ) {
    super(variaveisContextoService)
    this.http = injector.get(HttpClient)
    this.route = injector.get(ActivatedRoute)
  }

  pegarLista(): Observable<Retorno<T[]>> {
    const modulo = this.moduloUrl.slice(3,-1)
    this.preencherEmpresaId()

    if (this.routingsComEmpresaIdInicio.includes(modulo))
      return this.http.get<Retorno<T[]>>(
        this.baseUrlEmpresa + this.empresaId + '/' + this.moduloUrl.slice(3)
      )
    else if (this.routingsComEmpresaIdFinal.includes(modulo))
      return this.http.get<Retorno<T[]>>(
        this.baseUrl + this.moduloUrl + 'empresa/' + this.empresaId
      )
    else if (this.routingsComEmpresaIdSemEmpresa.includes(modulo))
      return this.http.get<Retorno<T[]>>(
        this.baseUrl + this.moduloUrl + this.empresaId
      )
    else if (this.routingsComEmpresasIdFinal.includes(modulo))
      return this.http.get<Retorno<T[]>>(
        this.baseUrl + this.moduloUrl.slice(1) + 'empresas/' + this.empresaId
      )
    else
      return this.http.get<Retorno<T[]>>(this.baseUrl + this.moduloUrl)
  }

  pegarPorId(id: number,data?: Date): Observable<Retorno<T>> {
    const modulo = this.moduloUrl.slice(4,-1)
    this.preencherEmpresaId()
    const params: string = data === null || data === undefined ? '' : '?data=' + data

    if (this.routingsComEmpresaIdSemEmpresa.includes(modulo))
      return this.http.get<Retorno<T>>(
        this.baseUrl + this.moduloUrl.slice(1) + this.empresaId + '/' + id + params
      )
    else
      return this.http.get<Retorno<T>>(this.baseUrl + this.moduloUrl + id + params)
  }

  salvar(modelo: T): Observable<Retorno<T>> {
    this.preencherEmpresaId()
    modelo.empresaId = this.empresaId
    return this.http.post<Retorno<T>>(this.baseUrl + this.moduloUrl, modelo)
  }

  alterar(modelo: T): Observable<Retorno<T>> {
    this.preencherEmpresaId()
    modelo.empresaId = this.empresaId
    return this.http.put<Retorno<T>>(this.baseUrl + this.moduloUrl, modelo)
  }

  excluir(id: number): Observable<Retorno<T>> {
    return this.http.delete<Retorno<T>>(this.baseUrl + this.moduloUrl + id)
  }

}
