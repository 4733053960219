<div placement="bottom-right">
  <div class="topbar-item">
    <div *ngFor="let sistema of botoesSistema"
         class="btn btn-icon btn-clean btn-lg mr-1"
         [ngClass]="{'db-menu-ativo': sistemaAtual === sistema.sistema}"
         [ngbTooltip]="sistema.tooltip">
      <span class="symbol symbol-35" (click)="mudarMenu(sistema.sistema)">
        <span matTooltip="{{sistema.tooltip}}" flow="down" class="symbol-label font-size-h5 font-weight-bold">
          <i class="fas" [ngClass]="sistema.icone"></i>
        </span>
      </span>
    </div>
  </div>
</div>