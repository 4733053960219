import { Injectable } from '@angular/core'
import { BehaviorSubject } from 'rxjs'
import { EmpresaContexto, EstabelecimentoContexto } from 'src/app/core/models/empresa/empresaContexto'
import { CriptografiaService } from 'src/app/core/shared/services/criptografia.service'

@Injectable({
  providedIn: 'root'
})
export class VariaveisContextoService {
  private STR_EMPRESA = 'EMPRESA'
  private STR_ESTABELECIMENTO = 'ESTABELECIMENTO'
  private STR_DATA_MOVIMENTO = 'DATA-MOVIMENTO'
  private empresa = new BehaviorSubject<EmpresaContexto>(null)
  private estabelecimento = new BehaviorSubject<EstabelecimentoContexto>(null)
  private dataMovimento = new BehaviorSubject<Date>(null)
  private _sistemaAberto = new BehaviorSubject<number>(null)
  empresaAtual = this.empresa.asObservable()
  estabelecimentoAtual = this.estabelecimento.asObservable()
  dataMovimentoAtual = this.dataMovimento.asObservable()
  sistemaAberto = this._sistemaAberto.asObservable()
  // implementar sistema aberto

  constructor(private criptografiaService: CriptografiaService) {
    const localStorageEmpresa: string = localStorage.getItem(this.STR_EMPRESA)
    if (localStorageEmpresa != null && localStorageEmpresa !== '')
      this.mudarEmpresa(JSON.parse(criptografiaService.descriptar(localStorageEmpresa)))

    const localStorageEstabelecimento: string = localStorage.getItem(this.STR_ESTABELECIMENTO)
    if (localStorageEstabelecimento != null && localStorageEstabelecimento !== '')
      this.mudarEstabelecimento(JSON.parse(criptografiaService.descriptar(localStorageEstabelecimento)))

    const localStorageDataMovimento: string = localStorage.getItem(this.STR_DATA_MOVIMENTO)
    if (localStorageDataMovimento != null && localStorageDataMovimento !== '')
      this.mudarDataMovimento(new Date(criptografiaService.descriptar(localStorageDataMovimento)))
  }

  mudarEmpresa(empresaParametro: EmpresaContexto) {
    if (empresaParametro != null && empresaParametro)
      localStorage.setItem(this.STR_EMPRESA, this.criptografiaService.encriptar(JSON.stringify(empresaParametro)))
    else
      localStorage.removeItem(this.STR_EMPRESA)
    this.empresa.next(empresaParametro)
  }

  mudarEstabelecimento(estabelecimentoParametro: EstabelecimentoContexto) {
    if (estabelecimentoParametro != null && estabelecimentoParametro)
      localStorage.setItem(this.STR_ESTABELECIMENTO, this.criptografiaService.encriptar(JSON.stringify(estabelecimentoParametro)))
    else
      localStorage.removeItem(this.STR_ESTABELECIMENTO)
    this.estabelecimento.next(estabelecimentoParametro)
  }

  mudarDataMovimento(dataMovimentoParametro?: Date) {
    if (dataMovimentoParametro != null && !isNaN(dataMovimentoParametro.getTime()))
      localStorage.setItem(this.STR_DATA_MOVIMENTO, this.criptografiaService.encriptar(dataMovimentoParametro.toString()))
    else
      localStorage.removeItem(this.STR_DATA_MOVIMENTO)
    this.dataMovimento.next(dataMovimentoParametro)
  }

}
