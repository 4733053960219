import { isDevMode, Component } from '@angular/core'
import { rotas } from 'src/app/constantes'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'
import { LayoutConfigService, ToggleOptions } from 'src/app/core/_base/layout'

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})
export class TopbarComponent {
  sistemasDisplay = true
  settingsDisplay = true
  chatDisplay = true
  languagesDisplay = true
  userDisplay = true
  notasLancamentoDisplay = true
  relatorioRapidoDisplay = true
  userLayout = 'offcanvas'
  userDropdownStyle = 'light'
  linkSettings = 'light'
  linkSuporte = ''

  toggleOptions: ToggleOptions = {
    target: KTUtil.getBody(),
    targetState: 'topbar-mobile-on',
    toggleState: 'active'
  }

  constructor(private layoutConfigService: LayoutConfigService, private autenticacaoService: AutenticacaoService) {
    this.chatDisplay = this.layoutConfigService.getConfig('extras.chat.display')
    this.notasLancamentoDisplay = this.layoutConfigService.getConfig('extras.notas-lancamento.display')
    this.relatorioRapidoDisplay = this.layoutConfigService.getConfig('extras.relatorio-rapido.display')
    this.languagesDisplay = this.layoutConfigService.getConfig('extras.languages.display')
    this.userDisplay = this.layoutConfigService.getConfig('extras.user.display')
    this.userLayout = this.layoutConfigService.getConfig('extras.user.layout')
    this.userDropdownStyle = this.layoutConfigService.getConfig('extras.user.dropdown.style')
    this.linkSettings = rotas.configuracoes
    this.linkSuporte = this.autenticacaoService.pegarUsuario().linkSuporte
  }
}
