// Angular
import { Component, OnInit } from '@angular/core'
// Layout
import { HtmlClassService } from 'src/app/views/theme/html-class.service'
import { LayoutConfigService, ToggleOptions } from 'src/app/core/_base/layout'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss'],
})
export class HeaderMobileComponent implements OnInit {
  // Public properties
  headerLogo = ''
  asideSelfDisplay = true
  headerMenuSelfDisplay = true
  headerMobileClasses = ''

  toggleOptions: ToggleOptions = {
    target: KTUtil.getBody(),
    targetState: 'topbar-mobile-on',
    toggleState: 'active'
  }

  constructor(private layoutConfigService: LayoutConfigService, private uiService: HtmlClassService,
              private autenticacaoService: AutenticacaoService) {
  }

  ngOnInit() {
    this.headerMobileClasses = this.uiService.getClasses('header_mobile', true).toString()
    this.headerLogo = this.getLogoUrl()
    this.asideSelfDisplay = this.layoutConfigService.getConfig('aside.self.display')
    this.headerMenuSelfDisplay = this.layoutConfigService.getConfig('header.menu.self.display')
  }

  getLogoUrl() {
    const usuario = this.autenticacaoService.pegarUsuario()
    return usuario.logo && usuario.logo !== '' ? usuario.logo : 'https://digibyteblob.blob.core.windows.net/digibyteblob/Digibyte.png'
  }
}
