import { Injectable } from '@angular/core'
import { CanActivate, Router } from '@angular/router'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { rotas } from 'src/app/constantes'
import { Usuario } from 'src/app/core/models/autenticacao/Usuario'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'

@Injectable({
  providedIn: 'root'
})
export class FiscalGuard implements CanActivate {
  modalRef: BsModalRef
  modulo = 'Fiscal'

  constructor(private authService: AutenticacaoService, private router: Router) {}

  canActivate(): boolean {
      const usuario: Usuario = this.authService.pegarUsuario()
      if (usuario.permissoes.some(p => p === this.modulo)) {
        return true
      }
      this.router.navigate(['/' + rotas.acessoNegado + '/' + this.modulo])
      return false
  }

}
