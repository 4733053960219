import { PrimengSharedModule } from 'src/app/shared-primeng.module'
// Angular
import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule } from '@angular/forms'
import { HttpClientModule } from '@angular/common/http'

// Partials
import { PartialsModule } from 'src/app/views/partials/partials.module'
// Pages
import { InlineSVGModule } from 'ng-inline-svg-2'
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar'
import { CoreModule } from 'src/app/core/core.module';

@NgModule({
  declarations: [
  ],
  exports: [],
  imports: [
    PrimengSharedModule,
    PerfectScrollbarModule,
    InlineSVGModule,
    CoreModule,
    CommonModule,
    HttpClientModule,
    FormsModule,
    PartialsModule
  ],
  providers: []
})
export class PagesModule {}
