<div class="modal-header">
  <h4 id="dialog-static-name" class="modal-title">Termo de uso do sistema</h4>
</div>

<div class="modal-body">
  <div [innerHTML]="termo"></div>
</div>

<div class="modal-footer">
  <div class="text-center float-right">
    <div class="btn-toolbar" role="toolbar">
      <div class="btn-toolbar justify-content-center" role="toolbar">
        <button *ngIf="podeFechar" type="button" class="btn btn-outline-digibyte btn-sm" (click)="bsModalRef.hide()">Fechar</button>
        <button type="button" class="btn btn-outline-digibyte btn-sm" (click)="aceitarTermo()">Aceitar</button>
      </div>
    </div>
  </div>
</div>