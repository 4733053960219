import { Injectable, Injector } from '@angular/core'
import { BaseApiService } from 'src/app/core/shared/services/base-api.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { Turno } from './turnos.model'

@Injectable({
  providedIn: 'root'
})
export class TurnosService extends BaseApiService<Turno> {
  constructor(protected injector: Injector, protected contexto: VariaveisContextoService) {
    super('v1/turnos/', injector, Turno.fromJson, contexto)
  }

}
