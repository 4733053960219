// Angular
import { Component, Input } from '@angular/core'

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
})
export class UserProfileComponent {
  // Public properties
  user$: any

  @Input() userDropdownStyle = 'light'
  @Input() avatar = true
  @Input() greeting = true
  @Input() badge: boolean
  @Input() icon: boolean

  constructor() {
  }

  logout() {}
}
