import { Component, Input, TemplateRef, ViewChild } from '@angular/core'
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { Subject } from 'rxjs'
import { rotas } from 'src/app/constantes'
import { ImportacaoExcel } from 'src/app/core/models/conciliacaoBancaria/importacaoExcel'
import { ImportacaoOfx } from 'src/app/core/models/conciliacaoBancaria/importacaoOfx'
import { Retorno } from 'src/app/core/models/retorno'
import { ConciliacaoBancariaService } from 'src/app/core/services/conciliacao-bancaria.service'
import { MensagemService } from 'src/app/core/services/mensagem.service'
import * as XLSX from 'xlsx'

@Component({
  selector: 'app-escolhe-extrato',
  templateUrl: './escolhe-extrato.component.html',
  styleUrls: ['./escolhe-extrato.component.css']
})
export class EscolheExtratoComponent {
  @ViewChild('modalColunas') modalColunas: TemplateRef<any>
  @Input() data: Date
  @Input() estabelecimentoId: number
  @Input() estabelecimentoNome: string
  @Input() contaCorrenteId: number
  @Input() contaCorrenteNome: string
  onAtualizacaoNecessaria: Subject<void>;

  fileType: string
  rotaExtratoOfx: string = `${rotas.conciliacaoBancaria}/${rotas.conciliacaoBancariaExtratoOfx}`
  rotaExtratoExcel: string = `${rotas.conciliacaoBancaria}/${rotas.conciliacaoBancariaExtratoExcel}`
  modalRef: BsModalRef

  arquivos: File[] = []
  teste: any[] = []
  extensao: string
  listaExtensoes: string[] = ['xlsx']
  colunas: any
  linhas: any[] = []
  colunasForm: UntypedFormGroup
  accept: string = '.xlsx, .ofx'

  constructor(
    public bsModalRef: BsModalRef,
    public router: Router,
    private mensagemService: MensagemService,
    private modalService: BsModalService,
    private conciliacaoBancariaService: ConciliacaoBancariaService,
    private fb: UntypedFormBuilder,
  )
  { }

  ngOnInit() {
    this.inicializarForm()
  }

  inicializarForm() {
    this.colunasForm = this.fb.group({
      data: ['', Validators.required],
      valor: ['', Validators.required],
      historico: ['', Validators.required],
      tipo: ['', Validators.required],
      contaCorrente: ['', Validators.required]
    })
  }

  fecharModal() { this.bsModalRef.hide() }

  importar() {
    if (!this.estabelecimentoId) {
      this.mensagemService.showAviso('Para fazer a consulta por favor selecione um estabelecimento!')
      return
    }

    if (this.arquivos != null && this.arquivos.length !== 1) {
      if (this.fileType === 'ofx') {
        this.mensagemService.showAviso('Para fazer a importação selecione um arquivo OFX')
      }
      else {
        this.mensagemService.showAviso('Para fazer a importação selecione um arquivo em EXCEL')
      }
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(this.arquivos[0])

    if (this.fileType === 'excel') {
      if (this.mensagemService.verificaFormGroup(this.colunasForm)) {
        reader.onload = () => {
          const importacaoExcel: ImportacaoExcel = {
            estabelecimentoId: this.estabelecimentoId,
            arquivo: reader.result.toString(),
            extensao: this.extensao,
            dataIndex: this.colunas.findIndex(c => c === this.colunasForm.get('data').value),
            valorIndex: this.colunas.findIndex(c => c === this.colunasForm.get('valor').value),
            historicoIndex: this.colunas.findIndex(c => c === this.colunasForm.get('historico').value),
            tipoIndex: this.colunas.findIndex(c => c === this.colunasForm.get('tipo').value),
            contaCorrenteIndex: this.colunas.findIndex(c => c === this.colunasForm.get('contaCorrente').value)
          }

          this.conciliacaoBancariaService
            .importarArquivoExcel(importacaoExcel).subscribe({
              next: (retorno: Retorno<any>) => {
                if (retorno.valido) {
                  this.mensagemService.showInformacao('Importação realizada com sucesso')
                  if (this.onAtualizacaoNecessaria instanceof Subject) {
                    this.onAtualizacaoNecessaria.next();
                  }
                  //this.movimento = new Date(retorno.retorno.data)
                  //this.carregarConciliacoesBancaria()
                } else
                  this.mensagemService.showAviso('Não foi possível realizara importação do arquivo OFX')
              },
              error: (error) => this.mensagemService.showErroApi(error)
            })
        }
      }
    } else if (this.fileType === 'ofx') {
      reader.onload = () => {
        const importacaoOfx: ImportacaoOfx = {
          estabelecimentoId: this.estabelecimentoId,
          contaCorrenteId: this.contaCorrenteId,
          arquivo: reader.result.toString()
        }

        this.conciliacaoBancariaService
          .importarArquivoOfx(importacaoOfx).subscribe({
            next: (retorno: Retorno<any>) => {
              if (retorno.valido) {
                this.mensagemService.showInformacao('Importação realizada com sucesso')
                if (this.onAtualizacaoNecessaria instanceof Subject) {
                  this.onAtualizacaoNecessaria.next();
                }
                this.fecharModal();
//                this.movimento = new Date(retorno.retorno.data)
//                this.carregarConciliacoesBancaria()
              } else
                this.mensagemService.showAviso('Não foi possível realizara importação do arquivo OFX')
            },
            error: (error) => this.mensagemService.showErroApi(error)
          })
      }
    }


  }

  abrirExtratoExcel() {
    this.router.navigate([this.rotaExtratoExcel])
    this.fecharModal()
  }

  abrirExtratoOfx() {
    this.router.navigate([this.rotaExtratoOfx])
    this.fecharModal()
  }


  closeModal() {
    this.fecharModal()
  }

  onSelect(event) {
    if (this.fileType === 'excel') {
      if (this.arquivos.length < 1) {
        const arquivo = event.addedFiles[0]
        this.extensao = arquivo.name.substr(arquivo.name.lastIndexOf('.') + 1)
        if (this.listaExtensoes.includes(this.extensao)) {
          let numeroLinhas = 0
          const reader: FileReader = new FileReader()
          reader.onload = (e: any) => {
            /* read workbook */
            const bstr: string = e.target.result
            const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary', raw: true })

            /* grab first sheet */
            const wsname: string = wb.SheetNames[0]
            const ws: XLSX.WorkSheet = wb.Sheets[wsname]

            /* save data */
            this.teste = ((XLSX.utils.sheet_to_json(ws, { header: 1 })) as any[])

            /* percorrer as linhas para mostrar ao cliente */
            this.teste.forEach(linha => {
              if (numeroLinhas < 6) {
                if (numeroLinhas === 0)
                  this.colunas = linha
                else
                  this.linhas.push(linha)
                numeroLinhas++
              } else
                return false
            })
          }
          reader.readAsBinaryString(arquivo)

          this.abrirModalColunas()
          this.arquivos.push(arquivo)
        } else
          this.mensagemService.showAviso('O arquivo tem que ser de extensão ' + this.listaExtensoes.join(', '))
      } else
        this.mensagemService.showAviso('Não é possível adicionar mais de um arquivo')
    } else if (this.fileType === 'ofx') {
      if (this.arquivos.length < 1) {
        const arquivo = event.addedFiles[0]
        const extensao = arquivo.name.substring(arquivo.name.length - 4)
        if (extensao === '.ofx' || extensao === '.OFX')
          this.arquivos.push(arquivo)
        else
          this.mensagemService.showAviso('Não é possível adicionar arquivo que não seja da extensão .ofx')
      } else
        this.mensagemService.showAviso('Não é possível adicionar mais de um arquivo')
    }
  }

  onRemove(event) {
    this.arquivos.splice(this.arquivos.indexOf(event), 1)

    if (this.fileType === 'excel') {
      this.teste = []
      this.colunas = null
      this.linhas = []
    }
  }

  abrirModalColunas() {
    this.modalRef = this.modalService.show(
      this.modalColunas, { class: 'modal-lg modal-dialog-scrollable' }
    )
  }
}
