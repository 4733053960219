import { Injectable, Injector } from '@angular/core'
import { BaseApiService } from 'src/app/core/shared/services/base-api.service'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { Departamento } from './departamento.model'

@Injectable({
  providedIn: 'root'
})
export class DepartamentosService extends BaseApiService<Departamento> {
  constructor(protected injector: Injector, protected contexto: VariaveisContextoService) {
    super('v1/departamentos/', injector, Departamento.fromJson, contexto)
  }
}
