import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnInit,
  Renderer2,
  ViewChild
} from '@angular/core'
import { filter, take } from 'rxjs/operators'
import { NavigationEnd, Router } from '@angular/router'
import * as objectPath from 'object-path'
import {
  LayoutConfigService,
  MenuAsideService,
  MenuConfigService,
  MenuOptions,
  OffcanvasOptions
} from 'src/app/core/_base/layout'
import { HtmlClassService } from 'src/app/views/theme/html-class.service'
import { ModalFormService } from 'src/app/core/shared/services/modal-form.service'
import { EscolhaEmpresaComponent } from './escolha-empresa/escolha-empresa.component'
import { BsModalService } from 'ngx-bootstrap/modal'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'
import { EmpresaService } from 'src/app/core/services/empresa.service'
import { AutenticacaoService } from 'src/app/core/services/autenticacao.service'
import {
  EscolheExtratoComponent
} from 'src/app/views/pages/contabilidade/conciliacao-bancaria/escolhe-extrato/escolhe-extrato.component'
import { EmpresaContexto, EstabelecimentoContexto } from 'src/app/core/models/empresa/empresaContexto'
import { EscolhaDataMovimentoComponent } from './escolha-data-movimento/escolha-data-movimento.component'
import { AcaoMenu } from 'src/app/core/_base/layout/models/acaoMenu.enum'
import { MensagemService } from 'src/app/core/services/mensagem.service'
import { EscolhaEstabelecimentoComponent } from './escolha-estabelecimento/escolha-estabelecimento.component'
import { verbos } from 'src/app/constantes'
import { rotas } from 'src/app/constantes'
import { Subscription } from 'rxjs'
import { ApoioService } from 'src/app/core/shared/services/apoio.service'

@Component({
  selector: 'app-aside-left',
  templateUrl: './aside-left.component.html',
  styleUrls: ['./aside-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AsideLeftComponent extends ModalFormService implements OnInit, AfterViewInit {
  private subs = new Subscription();
  private offcanvas: any
  verboIncluir: string = verbos.incluir

  @ViewChild('asideMenuOffcanvas', { static: true }) asideMenuOffcanvas: ElementRef
  @ViewChild('asideMenu', { static: true }) asideMenu: ElementRef

  asideLogo = ''
  asideClasses = ''
  currentRouteUrl = ''
  insideTm: any
  outsideTm: any
  esocialv2: Boolean = false

  menuCanvasOptions: OffcanvasOptions = {
    baseClass: 'aside',
    overlay: true,
    closeBy: 'kt_aside_close_btn',
    toggleBy: {
      target: 'kt_aside_mobile_toggle',
      state: 'mobile-toggle-active'
    }
  }

  menuOptions: MenuOptions = {
    // submenu setup
    submenu: {
      desktop: {
        // by default the menu mode set to accordion in desktop mode
        default: 'dropdown',
      },
      tablet: 'accordion', // menu set to accordion in tablet mode
      mobile: 'accordion' // menu set to accordion in mobile mode
    },

    // accordion setup
    accordion: {
      expandAll: false // allow having multiple expanded accordions in the menu
    }
  }

  /**
   * Component Constructor
   *
   * param htmlClassService: HtmlClassService
   * param menuAsideService
   * param layoutConfigService: LayoutConfigService
   * param router: Router
   * param render: Renderer2
   * param cd: ChangeDetectorRef
   */
  constructor(
    public htmlClassService: HtmlClassService,
    public menuAsideService: MenuAsideService,
    public layoutConfigService: LayoutConfigService,
    private empresaService: EmpresaService,
    private router: Router,
    private render: Renderer2,
    private cd: ChangeDetectorRef,
    private modalService: BsModalService,
    private variaveisContextoService: VariaveisContextoService,
    private autenticacaoService: AutenticacaoService,
    private mensagemService: MensagemService,
    private menuConfigService: MenuConfigService,
    private apoioService: ApoioService,
  ) {
    super()
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
    this.currentRouteUrl = this.router.url.split(/[?#]/)[0]

    this.subs.add(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(event => {
        this.currentRouteUrl = this.router.url.split(/[?#]/)[0]
        this.mobileMenuClose()
        this.cd.markForCheck()
      }))

    const config = this.layoutConfigService.getConfig()

    if (objectPath.get(config, 'aside.menu.dropdown')) {
      this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown', '1')
      // tslint:disable-next-line:max-line-length
      this.render.setAttribute(this.asideMenu.nativeElement, 'data-ktmenu-dropdown-timeout', objectPath.get(config, 'aside.menu.submenu.dropdown.hover-timeout'))
    }

    this.asideClasses = this.htmlClassService.getClasses('aside', true).toString()
    this.asideLogo = this.getAsideLogo()
    setTimeout(() => {
      this.offcanvas = new KTOffcanvas(this.asideMenuOffcanvas.nativeElement, this.menuCanvasOptions)
    })

    let numeroSistema = this.menuConfigService.pegarSistema()

    this.subs.add(this.empresaService.pegarEmpresas().subscribe(async retorno => {
      if (retorno.retorno === undefined || retorno.retorno.length === 0) {
        if (!this.router.url.startsWith('/empresa')) {
          setTimeout(() => {
            this.router.navigate(['/empresa/0', verbos.incluir])
            this.mensagemService.showSucesso('Pode cadastrar sua primeira empresa!')
          }, 1000)
        }
      } else {
        if (numeroSistema !== 3) {
          this.variaveisContextoService.empresaAtual.pipe(take(1)).subscribe(empresa => {
            if (empresa === null && this.autenticacaoService.logado()) {
              this.chamarMetodo(AcaoMenu.EscolhaEmpresa)
              this.cd.markForCheck()
              return
            }
          })
        }
        else
        {
         //fica em loop
          const escritorio = await this.apoioService.obterCodigoEscritorio();

          if (!escritorio) {
             this.router.navigate(['/' + rotas.semEscritorio])
          } else {
            this.variaveisContextoService.mudarEmpresa(escritorio);
       //     this.router.navigate(['/' + rotas.painelGerenciador])
          }
        }
      }
    }))
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  // evento do clique para esocial em uma nova página
  enviarPaginaEsocialv2() {
    let rote = '/#/' + rotas.eventosEsocial2
    window.open(rote.toString(), '_blank')
    // this.router.navigate([rote]);
  }
  //#region Fechar
  getAsideLogo() {
    let result = 'logo-light.png'
    const brandSelfTheme = this.layoutConfigService.getConfig('brand.self.theme') || ''
    if (brandSelfTheme === 'light') {
      result = 'logo-dark.png'
    }
    return `./assets/media/logos/${result}`
  }

  /**
   * Check Menu is active
   * @param item: any
   */
  isMenuItemIsActive(item): boolean {
    if (item.submenu) {
      return this.isMenuRootItemIsActive(item)
    }

    if (!item.page) {
      return false
    }

    return this.currentRouteUrl.indexOf(item.page) !== -1
  }

  /**
   * Check Menu Root Item is active
   * @param item: any
   */
  isMenuRootItemIsActive(item): boolean {
    let result = false

    for (const subItem of item.submenu) {
      result = this.isMenuItemIsActive(subItem)
      if (result) {
        return true
      }
    }

    return false
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseEnter(e: Event) {
    // check if the left aside menu is fixed
    if (document.body.classList.contains('aside-fixed')) {
      if (this.outsideTm) {
        clearTimeout(this.outsideTm)
        this.outsideTm = null
      }

      this.insideTm = setTimeout(() => {
        // if the left aside menu is minimized
        if (document.body.classList.contains('aside-minimize') && KTUtil.isInResponsiveRange('desktop')) {
          // show the left aside menu
          this.render.removeClass(document.body, 'aside-minimize')
          this.render.addClass(document.body, 'aside-minimize-hover')
        }
      }, 50)
    }
  }

  /**
   * Use for fixed left aside menu, to show menu on mouseenter event.
   * @param e Event
   */
  mouseLeave(e: Event) {
    if (document.body.classList.contains('aside-fixed')) {
      if (this.insideTm) {
        clearTimeout(this.insideTm)
        this.insideTm = null
      }

      this.outsideTm = setTimeout(() => {
        // if the left aside menu is expand
        if (document.body.classList.contains('aside-minimize-hover') && KTUtil.isInResponsiveRange('desktop')) {
          // hide back the left aside menu
          this.render.removeClass(document.body, 'aside-minimize-hover')
          this.render.addClass(document.body, 'aside-minimize')
        }
      }, 100)
    }
  }

  /**
   * Returns Submenu CSS Class Name
   * @param item: any
   */
  getItemCssClasses(item) {
    let classes = 'menu-item'

    if (objectPath.get(item, 'submenu')) {
      classes += ' menu-item-submenu'
    }

    if (!item.submenu && this.isMenuItemIsActive(item)) {
      classes += ' menu-item-active menu-item-here'
    }

    if (item.submenu && this.isMenuItemIsActive(item)) {
      classes += ' menu-item-open menu-item-here'
    }

    // custom class for menu item
    const customClass = objectPath.get(item, 'custom-class')
    if (customClass) {
      classes += ' ' + customClass
    }

    if (objectPath.get(item, 'icon-only')) {
      classes += ' menu-item-icon-only'
    }

    return classes
  }

  getItemAttrSubmenuToggle(item) {
    let toggle = 'hover'
    if (objectPath.get(item, 'toggle') === 'click') {
      toggle = 'click'
    } else if (objectPath.get(item, 'submenu.type') === 'tabs') {
      toggle = 'tabs'
    } else {
      // submenu toggle default to 'hover'
    }

    return toggle
  }

  mobileMenuClose() {
    if (KTUtil.isBreakpointDown('lg') && this.offcanvas) { // Tablet and mobile mode
      this.offcanvas.hide() // Hide offcanvas after general link click
    }
  }

  chamarMetodo(acao: AcaoMenu) {
    switch (acao) {
      case AcaoMenu.EscolhaEmpresa:
        this.escolhaEmpresa()
        break
      case AcaoMenu.EscolhaExtratoBancario:
        this.escolhaExtratoBancario()
        break
      case AcaoMenu.EscolhaDataMovimento:
        this.escolhaDataMovimento()
        break
      case AcaoMenu.EscolhaEstabelecimento:
        this.escolhaEstabelecimento()
        break
      default:
        this.mensagemService.showAviso('Ação não encontrada.')
    }
  }
  //#endregion
  //#region Ações do Menu
  private escolhaEmpresa() {
    let empresa: EmpresaContexto = null

    this.variaveisContextoService.empresaAtual.pipe(take(1)).subscribe(empresaAtual => {
      empresa = empresaAtual
      // pega a empresa atual para repassar a modal
      // caso simplesmente feche a modal retorna a empresa atual
      const initialState = { empresa }

      this.bsModalRef = this.modalService
        .show(EscolhaEmpresaComponent,
          { backdrop: true, ignoreBackdropClick: true, class: 'modal-dialog-scrollable', initialState })

      this.bsModalRef.content.onClose.subscribe((empresa: EmpresaContexto) => {
        this.variaveisContextoService.mudarEmpresa(empresa)
      })
    })
  }

  private escolhaEstabelecimento() {
    let estabelecimento: EstabelecimentoContexto = null

    this.variaveisContextoService.estabelecimentoAtual.pipe(take(1)).subscribe(estabelecimentoAtual => {
      estabelecimento = estabelecimentoAtual
      const initialState = { estabelecimento }

      this.bsModalRef = this.modalService
        .show(EscolhaEstabelecimentoComponent,
          { backdrop: true, ignoreBackdropClick: true, class: 'modal-dialog-scrollable', initialState })

      this.bsModalRef.content.onClose.subscribe((estabelecimento: EstabelecimentoContexto) => {
        this.variaveisContextoService.mudarEstabelecimento(estabelecimento)
      })
    })
  }

  private escolhaExtratoBancario() {
    const initialState = {}

    this.bsModalRef = this.modalService
      .show(EscolheExtratoComponent,
        { backdrop: true, ignoreBackdropClick: false, class: 'modal-dialog-scrollable', initialState })

    this.toggleAsideClick()
  }

  private escolhaDataMovimento() {
    this.variaveisContextoService.dataMovimentoAtual.pipe(take(1)).subscribe(dataMovimento => {
      dataMovimento = dataMovimento
      const initialState = { dataMovimento }

      this.bsModalRef = this.modalService
        .show(EscolhaDataMovimentoComponent,
          { backdrop: true, ignoreBackdropClick: true, class: 'modal-dialog-scrollable', initialState })

      this.bsModalRef.content.onClose.subscribe((dataMovimento: Date) => {
        this.variaveisContextoService.mudarDataMovimento(dataMovimento)
      })
    })
  }

  toggleAsideClick() {
    document.body.classList.toggle('aside-minimize')
  }
  //#endregion
}
