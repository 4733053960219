// Angular
import { NgModule, ModuleWithProviders  } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { NgSelectModule } from '@ng-select/ng-select'
// Layout Directives
// Services
import {
  AutoFocusDirective,
  ContentAnimateDirective,
  FirstLetterPipe,
  GetObjectPipe,
  HeaderDirective,
  JoinPipe,
  MenuDirective,
  OffcanvasDirective,
  SafePipe,
  ScrollTopDirective,
  SparklineChartDirective,
  StickyDirective,
  TabClickEventDirective,
  TimeElapsedPipe,
  ToggleDirective
} from './_base/layout'
// Components
import { CardHeaderListasComponent } from './shared/components/html-parts/card-header-listas/card-header-listas.component';
import { CardHeaderFormComponent } from './shared/components/html-parts/card-header-form/card-header-form.component';
import { CardFooterFormComponent } from './shared/components/html-parts/card-footer-form/card-footer-form.component';
import { CardHeaderCalculoFolhaComponent } from './shared/components/html-parts/card-header-calculo-folha/card-header-calculo-folha.component';
import { CardBodyCalculoFolhaComponent } from './shared/components/html-parts/card-body-calculo-folha/card-body-calculo-folha.component';
import { DataMovimentoComponent } from './shared/components/html-parts/data-movimento/data-movimento.component'
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PainelResumoComponent } from './shared/components/html-parts/painel-resumo/painel-resumo.component';
import { SpinnerDbComponent } from './shared/spinner-db/spinner-db.component'

export const bsDatepickerModuleForRoot: ModuleWithProviders<BsDatepickerModule> = BsDatepickerModule.forRoot()

@NgModule({
  imports: [
    CommonModule,
    bsDatepickerModuleForRoot,
    NgSelectModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule
  ],
  declarations: [
    // directives
    ScrollTopDirective,
    HeaderDirective,
    OffcanvasDirective,
    ToggleDirective,
    MenuDirective,
    TabClickEventDirective,
    SparklineChartDirective,
    ContentAnimateDirective,
    StickyDirective,
    AutoFocusDirective,
    // pipes
    TimeElapsedPipe,
    JoinPipe,
    GetObjectPipe,
    SafePipe,
    FirstLetterPipe,
    // components
    CardHeaderListasComponent,
    CardHeaderFormComponent,
    CardFooterFormComponent,
    CardHeaderCalculoFolhaComponent,
    CardBodyCalculoFolhaComponent,
    DataMovimentoComponent,
    PainelResumoComponent,
    SpinnerDbComponent
  ],
  exports: [
    // directives
    AutoFocusDirective,
    ContentAnimateDirective,
    HeaderDirective,
    MenuDirective,
    OffcanvasDirective,
    ScrollTopDirective,
    SparklineChartDirective,
    StickyDirective,
    TabClickEventDirective,
    ToggleDirective,
    // pipes
    FirstLetterPipe,
    GetObjectPipe,
    JoinPipe,
    SafePipe,
    TimeElapsedPipe,
    //components
    CardBodyCalculoFolhaComponent,
    CardFooterFormComponent,
    CardHeaderListasComponent,
    CardHeaderFormComponent,
    CardHeaderCalculoFolhaComponent,
    DataMovimentoComponent,
    PainelResumoComponent,
    SpinnerDbComponent
  ],
  providers: []
})
export class CoreModule {
}
