// Angular
import { BrowserModule } from '@angular/platform-browser'
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { HttpClientModule } from '@angular/common/http'
import {
  CommonModule,
  registerLocaleData,
  LocationStrategy,
  HashLocationStrategy,
} from '@angular/common'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { OverlayModule } from '@angular/cdk/overlay'
import ptBr from '@angular/common/locales/pt'
// Ngx Bootstrap
import { ptBrLocale } from 'ngx-bootstrap/locale'
import { defineLocale } from 'ngx-bootstrap/chronos'
// Perfect Scroll bar
import { PERFECT_SCROLLBAR_CONFIG, PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar'
// SVG inline
import { InlineSVGModule } from 'ng-inline-svg-2'
// Env
import { environment } from 'src/environments/environment'
// NGX Permissions
import { NgxPermissionsModule } from 'ngx-permissions'
// NGRX
import { StoreModule } from '@ngrx/store'
import { EffectsModule } from '@ngrx/effects'
import { StoreRouterConnectingModule } from '@ngrx/router-store'
import { StoreDevtoolsModule } from '@ngrx/store-devtools'
// State
import { metaReducers, reducers } from './core/reducers'
// Components
import { AppComponent } from './app.component'
// Modules
import { AppRoutingModule } from './app-routing.module'
import { CoreModule } from './core/core.module'
import { ThemeModule } from './views/theme/theme.module'
// Autenticação com JWToken
import { JwtModule } from '@auth0/angular-jwt'
// Partials
import { PartialsModule } from './views/partials/partials.module'
// Layout Services
import {
  DataTableService,
  KtDialogService,
  LayoutConfigService,
  LayoutRefService,
  MenuAsideService,
  MenuConfigService,
  MenuHorizontalService,
  PageConfigService,
  SplashScreenService,
  SubheaderService
} from './core/_base/layout'
// CRUD
import {
  HttpUtilsService,
  LayoutUtilsService,
  TypesUtilsService
} from './core/_base/crud'
// Config
import { LayoutConfig } from './core/_config/layout.config'
// Highlight JS
import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs'
import xml from 'highlight.js/lib/languages/xml'
import json from 'highlight.js/lib/languages/json'
import scss from 'highlight.js/lib/languages/scss'
import typescript from 'highlight.js/lib/languages/typescript';

import { MyMonitoringService } from './core/shared/services/logging.service'
// ngx-toast
import { ToastrModule } from 'ngx-toastr'
import { ErrorHandlerService } from './core/shared/services/errorHandlerService.service'
import { TooltipModule } from 'ngx-bootstrap/tooltip'

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelSpeed: 0.5,
  swipeEasing: true,
  minScrollbarLength: 40,
  maxScrollbarLength: 300
}

export function initializeLayoutConfig(appConfig: LayoutConfigService) {
  // initialize app by loading default demo layout config
  return () => {
    if (appConfig.getConfig() === null) {
      appConfig.loadConfigs(new LayoutConfig().configs)
    }
  }
}

registerLocaleData(ptBr)
defineLocale('pt-br', ptBrLocale)

/**
 * Import specific languages to avoid importing everything
 * The following will lazy load highlight.js core script (~9.6KB) + the selected languages bundle (each lang. ~1kb)
 */
export function getHighlightLanguages() {
  return [
    {name: 'typescript', func: typescript},
    {name: 'scss', func: scss},
    {name: 'xml', func: xml},
    {name: 'json', func: json}
  ]
}

/**
 * Pega o token que está no local storage
 */
export function tokenGetter() {
  return localStorage.getItem('TOKEN')
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPermissionsModule.forRoot(),
    HighlightModule,
    PartialsModule,
    CoreModule,
    OverlayModule,
    JwtModule.forRoot({
      config: {
         tokenGetter,
         allowedDomains: [environment.apiUrlJwtToken, environment.apiNfseUrlJwtToken]
      }
    }),
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot({stateKey: 'router'}),
    StoreDevtoolsModule.instrument(),
    TranslateModule.forRoot(),
    MatProgressSpinnerModule,
    InlineSVGModule.forRoot(),
    ThemeModule,
    ToastrModule.forRoot(), // ngx-toast
    TooltipModule.forRoot(),
  ],
  exports: [],
  providers: [
 //   MyMonitoringService, { provide: ErrorHandler, useClass: ErrorHandlerService },
    LayoutConfigService,
    LayoutRefService,
    MenuConfigService,
    PageConfigService,
    KtDialogService,
    DataTableService,
    SplashScreenService,
    {provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG},
    {
      // layout config initializer
      provide: APP_INITIALIZER,
      useFactory: initializeLayoutConfig,
      deps: [LayoutConfigService],
      multi: true
    },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        languages: getHighlightLanguages
      }
    },
    // template services
    SubheaderService,
    MenuHorizontalService,
    MenuAsideService,
    HttpUtilsService,
    TypesUtilsService,
    LayoutUtilsService,
    // # nas rotas para poder acessa-las de qualquer lugar
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    // colocando tudo em português
    {provide: LOCALE_ID, useValue: 'pt-BR'}
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
