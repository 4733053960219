import { BaseModel } from 'src/app/core/shared/models/base-model.model'

export class Turno extends BaseModel {
  constructor(
    public id?: number,
    public nome?: string,
    public descricao?: string,
    public tipoJornada?: number,
    public horarioNoturno?: boolean,
    public segunda?: any,
    public terca?: any,
    public quarta?: any,
    public quinta?: any,
    public sexta?: any,
    public sabado?: any,
    public domingo?: any,
  ) { super() }

  static fromJson(jsonDados: any): Turno {
    return Object.assign(new Turno(), jsonDados)
  }
}
