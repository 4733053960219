import { Component, Input } from '@angular/core'
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { classeErro } from 'src/app/constantes'

@Component({
  selector: 'app-erro-campo',
  template: `<p *ngIf="deveMostrarMensagemErro()" class="text-danger {{classeDeErro}}">
    {{ mensagemErro }}
  </p>`
})
export class ErroCampoComponent {
  classeDeErro: string
  @Input() formControl: UntypedFormControl | UntypedFormGroup | UntypedFormArray

  constructor() { this.classeDeErro = classeErro }

  public deveMostrarMensagemErro(): boolean {
    return this.formControl.invalid && this.formControl.touched
  }

  public get mensagemErro(): string | null {

    if (this.formControl.errors.required)
      return 'Campo obrigatório'
    if (this.formControl.errors && this.formControl.errors.mask && this.formControl.errors.mask.requiredMask == "00.000.000/0000-00")
      return "CNPJ inválido"
    if (this.formControl.errors && this.formControl.errors.mask && this.formControl.errors.mask.requiredMask == "000.000.000-00")
      return "CPF inválido"
    else if (this.formControl.errors.minlength)
      return `Deve ter no mínimo ${this.formControl.errors.minlength.requiredLength} caracteres`
    else if (this.formControl.errors.maxlength)
      return `Deve ter no máximo ${this.formControl.errors.maxlength.requiredLength} caracteres`
    else if (this.formControl.errors.min)
      return `Deve ser igual ou maior que ${this.formControl.errors.min.min}`
    else if (this.formControl.errors.email)
      return 'Formato de email inválido'
    else if (this.formControl.errors.bsDate)
      return 'Data inválida'
    else if (this.formControl.errors.erro)
      return this.formControl.errors.erro.value
    else if (this.formControl.errors.errorMessage)
      return this.formControl.errors.errorMessage
    else if (this.formControl.errors)
      return 'Por favor preencha o campo corretamente'
    else
      return null
  }
}
