// Angular
import { Injectable } from '@angular/core'
// RxJS
import { Subject } from 'rxjs'
// Services
import { CriptografiaService } from 'src/app/core/shared/services/criptografia.service'
// Layout
import { MenuConfig } from '../../../_config/menu.config'
import { ModulosService } from './../../../shared/services/modulos.service'
import { rotas } from 'src/app/constantes'
import { Router } from '@angular/router'
import { Sistema } from '../models/sistema.enum'

@Injectable()
export class MenuConfigService {
  // Public properties
  onConfigUpdated$: Subject<any>
  // Private properties
  private menuConfig: any

  /**
   * Service Constructor
   */
  constructor(
    private criptografiaService: CriptografiaService,
    private modulosService: ModulosService,
    private router: Router
  ) {
    // register on config changed event and set default config
    this.onConfigUpdated$ = new Subject()
  }

  /**
   * Returns the menuConfig
   */
  getMenus() {
    return this.menuConfig
  }

  /**
   * Load config
   *
   * @param config: any
   */

  loadConfigs() {
    switch(this.pegarSistema()) {
      case Sistema.Contabil: {
        this.menuConfig = new MenuConfig().contabilConfigs;
        break;
      }
      case Sistema.Folha: {
        this.menuConfig = new MenuConfig().folhaConfigs;
        break;
      }
      case Sistema.Fiscal: {
        this.menuConfig = new MenuConfig().fiscalConfigs;
        break;
      }
      case Sistema.Gerenciador: {
        this.menuConfig = new MenuConfig().gerenciadorConfigs;
      break;
      }
      default: {
        this.menuConfig = new MenuConfig().semSistemaConfigs;
        break;
      }
    }
    this.onConfigUpdated$.next(this.menuConfig);
  }

  pegarSistema(): Sistema {
    let sistemaStorage: Sistema = this.pegarSistemaAberto()
    let sistemasLiberados: string[] = this.modulosService.modulosLiberados
    let sistemaStorageString = this.modulosService.converterSistemaParaString(sistemaStorage)

    // Verifica se o sistema armazenado está liberado
    if (sistemaStorageString && sistemasLiberados.includes(sistemaStorageString)) {
      return sistemaStorage
    }

    // Caso o sistema armazenado não esteja liberado ou não exista, busca o primeiro liberado
    let primeiroLiberado = sistemasLiberados.length > 0 ? sistemasLiberados[0] : null

    if (primeiroLiberado) {
      sistemaStorage = this.modulosService.converterStringParaSistema(primeiroLiberado)
      this.salvarSistemaAberto(sistemaStorage)
      return sistemaStorage
    } else {
      // Nenhum módulo liberado, redirecionar para a página de acesso negado
      this.redirecionarParaAcessoNegado()
    }
  }

  salvarSistemaAberto(sistema: Sistema) {
    localStorage.setItem('SISTEMA', this.criptografiaService.encriptar(JSON.stringify(sistema)))
  }

  pegarSistemaAberto(): Sistema {
    const valorCriptografado = localStorage.getItem('SISTEMA');
    if (valorCriptografado) {
      const valorDescriptografado = this.criptografiaService.descriptar(valorCriptografado);
      return JSON.parse(valorDescriptografado);
    } else {
      return null;
    }
  }

  redirecionarParaAcessoNegado() {
    this.router.navigate(['/' + rotas.acessoNegado + '/Contabil, Fiscal, Folha, Gerenciador'])
  }

}
