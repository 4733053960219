import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { TituloListasComponent } from './titulo-listas.component'

// Angular
@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [
        TituloListasComponent
    ],
    exports: [
        TituloListasComponent
    ]
  })
  export class TituloListasModule { }
