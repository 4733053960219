import { BaseModel } from 'src/app/core/shared/models/base-model.model'
import { DadosRubrica } from './dados-rubrica.model'
import { IdeRubrica } from './ide-rubrica.model'

export class Rubrica extends BaseModel {
  constructor(
    public id?: number,
    public empresaId?: number,
    public ordem?: number,
    public origem?: number,
    public percentual?: number,
    public tipoReferencia?: number,
    public intencao?: number,
    public ideRubrica?: IdeRubrica,
    public dadosRubrica?: DadosRubrica,
    public descricao?: string,
    public codigo?: string,
    public mediaDecimo?: number,
    public mediaFerias?: number
  ) { super() }

  static fromJson(jsonDados: any): Rubrica {
    return Object.assign(new Rubrica(), jsonDados)
  }
}
