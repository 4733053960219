import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { MensagemService } from 'src/app/core/services/mensagem.service'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Subject, Subscription } from 'rxjs'
import { Retorno } from 'src/app/core/models/retorno'
import { EmpresaService } from 'src/app/core/services/empresa.service'
import { Router } from '@angular/router'
import { EmpresaContexto, EstabelecimentoContexto } from 'src/app/core/models/empresa/empresaContexto'
import { AutoFocusDirective } from 'src/app/core/_base/layout'
import { rotas, verbos } from 'src/app/constantes'
import { Table } from 'primeng/table'

@Component({
  selector: 'app-escolha-empresa',
  templateUrl: './escolha-empresa.component.html',
  styleUrls: ['./escolha-empresa.component.scss'],
  // tslint:disable-next-line: no-host-metadata-property
  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"',
    '[style.overflow]': '"hidden"'
  }
})
export class EscolhaEmpresaComponent implements OnInit {
  private subs = new Subscription();
  pesquisaEmpresa: string
  empresas: EmpresaContexto[]
  empresa: EmpresaContexto = null
  estabelecimento: EstabelecimentoContexto = null
  focus: AutoFocusDirective
  verboIncluir: string = verbos.incluir
  modalAviso: Boolean;
  ativas: Boolean = true

  public onClose: Subject<EmpresaContexto>
  public onClosed: Subject<EstabelecimentoContexto>

  constructor(
    private mensagemService: MensagemService,
    public bsModalRef: BsModalRef,
    private empresaService: EmpresaService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit() {
    this.onClose = new Subject<EmpresaContexto>()
    this.onClosed = new Subject<EstabelecimentoContexto>()
    this.modalAviso = false;
    this.carregarEmpresas()
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  carregarEmpresas() {
    this.ativas = true;
    this.subs.add(this.empresaService.pegarEmpresas().subscribe({
      next: (retorno: Retorno<any>) => {
        if (retorno.valido || retorno.retorno === undefined) {
          if (retorno.retorno.length === 0) {
            this.mensagemService.showAviso('Por favor, cadastre uma empresa.')
            this.bsModalRef.hide()
            this.cd.markForCheck()
            this.router.navigate(['/empresa/0', this.verboIncluir])
          } else {
            this.empresas = retorno.retorno
            this.cd.markForCheck()
            return
          }
        }
      },
      error: (error) => this.mensagemService.showErroApi(error)
    }))
  }

  carregarEmpresasInativas() {
    this.ativas = false;
    this.subs.add(this.empresaService.pegarEmpresasInativas().subscribe({
      next: (retorno: Retorno<any>) => {
        if (retorno.valido) {
          this.empresas = retorno.retorno
          this.cd.markForCheck()
          return
        }
      },
      error: (error) => this.mensagemService.showErroApi(error)
    }))
  }

  fecharModal() {
    if (this.empresa === null || this.empresa === undefined) {
      this.mensagemService.showAviso('Por favor, escolha uma empresa.')
    } else {
      this.onClose.next(this.empresa)
      this.router.navigate(['/' + rotas.dashboard])
      this.cd.markForCheck()
      this.bsModalRef.hide()
    }
  }

  clear(table: Table) {
    table.clear();
  }

  selecionarEmpresa(empresa: any) {
    this.empresa = empresa
    if ((this.ativas === true) && (this.empresa.estabelecimentos.length === 0)) {
      this.modalAviso = true;
      this.router.navigate(['/' + rotas.estabelecimento + '/' + empresa.id + '/0', this.verboIncluir])
      setTimeout(() => { this.fecharModal() }, 2000)
      this.cd.markForCheck()
    } else {
      this.fecharModal()
    }
    this.cd.markForCheck()
  }

}
