// Angular
import { Component, Input, OnInit  } from '@angular/core'


@Component({
  selector: 'app-configuracoes',
  templateUrl: './configuracoes.component.html'
})
export class ConfiguracoesComponent implements OnInit{
  @Input() icon = 'flaticon2-shopping-cart-1'
  @Input() iconType: '' | 'brand'
  @Input() useSVG: boolean
  @Input() bgImage: string
  @Input() linkSuporte: string
  sistema:any;
  constructor() {
  }
  ngOnInit(): void {
    this.sistema =  { tooltip: 'Configurações' }
  }

}
