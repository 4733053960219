import { BaseModel } from 'src/app/core/shared/models/base-model.model'
import { DadosLotacao } from './dados-lotacao.model'
import { IdeLotacao } from './ide-lotacao.model'

export class LotacaoTributaria extends BaseModel {
  constructor(
    public id?: number,
    public empresaId?: number,
    public descricao?: string,
    public tipoLotacaoTributariaId?: number,
    public ideLotacao?: IdeLotacao,
    public dadosLotacao?: DadosLotacao,
    public s1020Lista?: any[]
  ) { super() }

  static fromJson(jsonDados: any): LotacaoTributaria {
    return Object.assign(new LotacaoTributaria(), jsonDados)
  }
}
