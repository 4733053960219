import { Injectable } from '@angular/core'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { Subscription } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class ModalFormService {
  bsModalRef: BsModalRef
  subscriptions: Subscription[] = []

  constructor() { }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe()
    })
    this.subscriptions = []
  }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) )
  }

}
