<div class="modal-header header-grid">
  <h4 class="modal-title">Estabelecimentos</h4>
</div>
<div class="form-row" style="padding: 1.41rem;">
  <div class="input-group col-md-6">
    <input
      type="search"
      class="form-control form-control-sm box-none"
      [(ngModel)]="pesquisaEstabelecimento"
      placeholder="Pesquisar"
      autofocus
    />
  </div>
</div>
<div class="modal-body">
  <table style="width:100%" class="table table-secondary table-hover table-sm table-modal-grid">
    <thead>
      <tr class="bg-digibyte text-light">
        <th class="border-form">Nome</th>
      </tr>
    </thead>
    <tbody>
      <tr
        *ngFor="let e of estabelecimentos | filterBy:['nome']:pesquisaEstabelecimento;
        let i = index" class="db-clicavel"
      >
        <td (click)="selecionarEstabelecimento(e)">{{ e.cpfCnpj }} - {{ e.nome }}</td>
      </tr>
    </tbody>
  </table>
</div>
<div class="modal-footer footer-grid">
  <button type="button" (click)="fecharModal()" class="btn btn-outline-light btn-sm float-right"
  >Fechar</button>
</div>
