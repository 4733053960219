import { rotas, verbos } from 'src/app/constantes';
import { Estabelecimento } from 'src/app/core/models/estabelecimento';
import { EstabelecimentoContexto } from 'src/app/core/models/empresa/empresaContexto'
import { ChangeDetectorRef, Component, OnInit } from '@angular/core'
import { Subject, Subscription } from 'rxjs'
import { MensagemService } from 'src/app/core/services/mensagem.service'
import { BsModalRef } from 'ngx-bootstrap/modal'
import { EmpresaService } from 'src/app/core/services/empresa.service'
import { Router } from '@angular/router'
import { Retorno } from 'src/app/core/models/retorno'
import { VariaveisContextoService } from 'src/app/core/shared/services/variaveis-contexto.service'

@Component({
  selector: 'app-escolha-estabelecimento',
  templateUrl: './escolha-estabelecimento.component.html',

  host: {
    '[style.display]': '"flex"',
    '[style.flex-direction]': '"column"',
    '[style.overflow]': '"hidden"'
  }
})
export class EscolhaEstabelecimentoComponent implements OnInit {
  private subs = new Subscription();
  verbosIncluir: string = verbos.incluir
  pesquisaEstabelecimento: string
  estabelecimentos: EstabelecimentoContexto[]
  estabelecimento: EstabelecimentoContexto

  public onClose: Subject<EstabelecimentoContexto>

  constructor(
    private mensagemService: MensagemService,
    public bsModalRef: BsModalRef,
    private empresaService: EmpresaService,
    private variaveisContextoService: VariaveisContextoService,
    private router: Router,
    private cd: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.onClose = new Subject<EstabelecimentoContexto>()
    this.carregarEstabelecimentos()
  }

  ngOnDestroy() {
    this.subs.unsubscribe()
  }

  carregarEstabelecimentos() {
    this.subs.add(this.empresaService.pegarEstabelecimentosPorEmpresaSelecionada().subscribe({
      next: (retorno: Retorno<Estabelecimento[]>) => {
        if (retorno.valido) {
          if (retorno.retorno.length === 0) {
            this.mensagemService.showAviso('Por favor, cadastre um estabelecimento.')
            this.bsModalRef.hide()
            this.router.navigate(['/' + rotas.estabelecimento + '/0', this.verbosIncluir])
          } else if (retorno.retorno.length === 1) {
            setTimeout(() => { this.selecionarEstabelecimento(retorno.retorno[0]) }, 150)
          } else {
            setTimeout(() => { this.selecionarEstabelecimento(retorno.retorno[0]) }, 150)
            // this.estabelecimentos = retorno.retorno
            // this.estabelecimentos.forEach(estabelecimento => {
            //   estabelecimento.cpfCnpj = `${estabelecimento.cpfCnpj.slice(0,2)}.${estabelecimento.cpfCnpj.slice(2,5)}.${estabelecimento.cpfCnpj.slice(5,8)}/${estabelecimento.cpfCnpj.slice(8,12)}-${estabelecimento.cpfCnpj.slice(12,14)}`
            // })
          }
        }
      },
      error: (error) => this.mensagemService.showErroApi(error)
    }))
  }

  fecharModal() {
    if (this.estabelecimento === null || this.estabelecimento === undefined)
      this.mensagemService.showAviso('Por favor, escolha um estabelecimento.')
    else {
      this.onClose.next(this.estabelecimento)
      this.router.navigate(['/' + rotas.dashboard])
      this.cd.markForCheck()
      this.bsModalRef.hide()
    }
  }

  selecionarEstabelecimento(estabelecimento: EstabelecimentoContexto) {
    this.estabelecimento = {
      'id': estabelecimento.id,
      'nome': estabelecimento.nome,
      'cpfCnpj': estabelecimento.cpfCnpj
    }
    this.fecharModal()
  }

}
